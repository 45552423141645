define('account-opening/components/twapi-plan-access-selection', ['exports', 'twapi/components/twapi-plan-access-selection'], function (exports, _twapiPlanAccessSelection) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _twapiPlanAccessSelection.default;
    }
  });
});