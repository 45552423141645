define('account-opening/components/step/security-equifax', ['exports', 'ember-decorators/object', 'twapi/utils/validation'], function (exports, _object, _validation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _dec3, _desc, _value, _obj;

  var Component = Ember.Component,
      _Ember$computed = Ember.computed,
      alias = _Ember$computed.alias,
      bool = _Ember$computed.bool,
      service = Ember.inject.service,
      get = Ember.get;
  exports.default = Component.extend((_dec = (0, _object.computed)('hasDecision', 'verificationResult.isAccepted', 'comparisonResult.isAccepted'), _dec2 = (0, _object.computed)('questions.@each.isAnswered'), _dec3 = (0, _object.computed)('hasDecision', 'hasPendingOrApprovedApplication'), (_obj = {
    equifax: service(),

    classNames: 'step-security-equifax',

    comparisonResult: alias('equifax.comparisonResult'),

    verificationResult: alias('equifax.verificationResult'),

    hasPendingOrApprovedApplication: alias('equifax.hasPendingOrApprovedApplication'),

    questions: alias('equifax.questions'),

    hasDecision: bool('verificationResult.decision'),

    quizPending: alias('equifax.quizPending'),

    isAccepted: function isAccepted(hasDecision, _isAccepted, comparisonAccepted) {
      return hasDecision && _isAccepted || comparisonAccepted;
    },
    allQuestionsAnswered: function allQuestionsAnswered(questions) {
      return questions.isEvery('isAnswered');
    },
    equifaxConfirmed: function equifaxConfirmed(hasDecision, hasPendingOrApprovedApplication) {
      return hasDecision || !hasPendingOrApprovedApplication;
    },


    actions: {
      submit: function submit() {
        get(this, 'equifax').submitQuiz();
      }
    }
  }, (_applyDecoratedDescriptor(_obj, 'isAccepted', [_dec], Object.getOwnPropertyDescriptor(_obj, 'isAccepted'), _obj), _applyDecoratedDescriptor(_obj, 'allQuestionsAnswered', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'allQuestionsAnswered'), _obj), _applyDecoratedDescriptor(_obj, 'equifaxConfirmed', [_dec3], Object.getOwnPropertyDescriptor(_obj, 'equifaxConfirmed'), _obj)), _obj))).reopen(_validation.build({
    equifaxConfirmed: { presence: true }
  }));
});