define('account-opening/components/twapi-forgot-password-form', ['exports', 'twapi/components/twapi-forgot-password-form'], function (exports, _twapiForgotPasswordForm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _twapiForgotPasswordForm.default;
    }
  });
});