define('account-opening/components/account/account-type-section', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      get = Ember.get,
      isPresent = Ember.isPresent,
      later = Ember.run.later,
      set = Ember.set;
  exports.default = Component.extend({
    f: null,

    optionValues: [],

    fieldKey: null,

    hasDefaultSelection: false,

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this._selectDefaultValue();
    },


    actions: {
      onAccountTypeSelected: function onAccountTypeSelected() {
        this.onAccountTypeSelected();
      }
    },

    onAccountTypeSelected: function onAccountTypeSelected() {},

    _selectDefaultValue: function _selectDefaultValue() {
      var _this = this;

      if (!get(this, 'hasDefaultSelection')) {
        return;
      }

      later(function () {
        var fieldKey = get(_this, 'fieldKey');
        var model = get(_this, 'f.scope');

        var optionValue = get(model, fieldKey);
        var optionValues = get(_this, 'optionValues');

        if (isPresent(optionValues) && !optionValues.includes(optionValue)) {
          set(model, fieldKey, get(optionValues, 'firstObject'));

          _this.onAccountTypeSelected();
        }
      });
    }
  });
});