define('account-opening/services/joint-account', ['exports', 'ember-decorators/object', 'twapi/models/applicant', 'twapi/models/applicant-data'], function (exports, _object, _applicant, _applicantData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _dec3, _desc, _value, _obj;

  var A = Ember.A,
      Service = Ember.Service,
      readOnly = Ember.computed.readOnly,
      get = Ember.get,
      service = Ember.inject.service,
      isNone = Ember.isNone,
      set = Ember.set;


  var FULL_ESTATE_PERCENTAGE = 100;

  exports.default = Service.extend((_dec = (0, _object.computed)('_primaryApplicant', 'accountOpening.customer', 'accountOpening.customer.{' + _applicantData.CUSTOMER_APPLICANT_PROPERTIES.join(',') + '}'), _dec2 = (0, _object.computed)('otherApplicants.[]'), _dec3 = (0, _object.computed)('primaryApplicant', 'otherApplicants.[]'), (_obj = {

    accountOpening: service(),

    _initializePercentages: true,

    isTenantsInCommon: readOnly('accountOpening.application.accountType.isJointTenantsInCommon'),

    isContinuation: readOnly('accountOpening.isJointAccountContinuation'),

    _primaryApplicant: _applicant.default.create(),

    primaryApplicant: function primaryApplicant(applicant, customer) {
      return (0, _applicantData.fromCustomer)(applicant, customer);
    },
    secondaryApplicant: function secondaryApplicant(otherApplicants) {
      return get(otherApplicants, 'firstObject');
    },


    otherApplicants: A([_applicant.default.create()]),

    applicants: function applicants(primaryApplicant, otherApplicants) {
      var applicants = A([primaryApplicant]);
      applicants.pushObjects(otherApplicants);

      return applicants;
    },
    clearPercentages: function clearPercentages() {
      var applicants = get(this, 'applicants');

      applicants.forEach(function (applicant) {
        return set(applicant, 'applicantData.estatePercent', null);
      });
    },
    equalizePercentages: function equalizePercentages(applicants) {
      var share = Math.floor(FULL_ESTATE_PERCENTAGE / applicants.length);

      var total = applicants.reduce(function (sum, a) {
        set(a, 'applicantData.estatePercent', share);

        return sum + share;
      }, 0);

      var remainder = FULL_ESTATE_PERCENTAGE - total;

      set(applicants, 'lastObject.applicantData.estatePercent', share + remainder);
    },
    initializePercentages: function initializePercentages() {
      var applicants = get(this, 'applicants');

      if (get(this, 'isTenantsInCommon')) {
        var missingPercentages = applicants.any(function (applicant) {
          return isNone(get(applicant, 'applicantData.estatePercent'));
        });

        if (missingPercentages) {
          this.equalizePercentages(applicants);
        }
      } else {
        this.clearPercentages();
      }
    }
  }, (_applyDecoratedDescriptor(_obj, 'primaryApplicant', [_dec], Object.getOwnPropertyDescriptor(_obj, 'primaryApplicant'), _obj), _applyDecoratedDescriptor(_obj, 'secondaryApplicant', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'secondaryApplicant'), _obj), _applyDecoratedDescriptor(_obj, 'applicants', [_dec3], Object.getOwnPropertyDescriptor(_obj, 'applicants'), _obj)), _obj)));
});