define('account-opening/initializers/raven', ['exports', 'raven'], function (exports, _raven) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(app) {
    if (app.environment !== 'development') {
      _raven.default.config('https://ff0df1ca3cd3442c8960f2f2988f5466@o8877.ingest.sentry.io/4504414673108992', {
        ignoreErrors: [/^Can't find variable: Cookies$/, /^Cookies is not defined$/, 'Unhandled Promise error detected', 'TransitionAborted']
      }).addPlugin(_raven.default.Plugins.Ember, Ember).install();
    }
  }

  exports.default = {
    name: 'raven',
    initialize: initialize
  };
});