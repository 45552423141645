define('account-opening/components/account/margin-type-description', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      _Ember$computed = Ember.computed,
      alias = _Ember$computed.alias,
      readOnly = _Ember$computed.readOnly,
      service = Ember.inject.service;
  exports.default = Component.extend({
    accountOpening: service(),

    marginDocument: alias('accountOpening.marginDocument'),

    marginType: readOnly('application.marginOrCash'),

    isMargin: readOnly('application.isMargin'),

    application: readOnly('accountOpening.application')
  });
});