define('account-opening/components/account/joint-continuation', ['exports', 'twapi/utils/computed', 'account-opening/controllers/step/account'], function (exports, _computed, _account) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      _Ember$computed = Ember.computed,
      alias = _Ember$computed.alias,
      readOnly = _Ember$computed.readOnly,
      service = Ember.inject.service;
  exports.default = Component.extend({
    accountOpening: service(),

    jointAccountContinuation: service(),

    config: _computed.default.registry.resolve('config:environment'),

    ownershipType: _account.OWNERSHIP_TYPES.JOINT,

    accountManagementUrl: alias('accountOpening.accountManagementUrl'),

    hasPendingOrApprovedApplication: readOnly('customer.hasPendingOrApprovedApplication'),

    marginDocument: alias('accountOpening.marginDocument'),

    marginType: readOnly('application.marginOrCash'),

    isMargin: readOnly('application.isMargin'),

    accountTypeName: readOnly('application.accountType.name'),

    application: readOnly('accountOpening.application'),

    primaryApplicant: readOnly('jointAccountContinuation.primaryApplicant')
  });
});