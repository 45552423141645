define('account-opening/mixins/step-controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = Ember.Mixin,
      get = Ember.get,
      service = Ember.inject.service;
  exports.default = Mixin.create({
    step: service(),

    _nextStep: function _nextStep(step) {
      var nextStep = get(this, 'step').nextStep(step);

      return this.transitionToRoute(get(nextStep, 'route'));
    }
  });
});