define('account-opening/components/access/objective-section', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      get = Ember.get,
      service = Ember.inject.service;
  exports.default = Component.extend({

    accountOpening: service(),

    actions: {
      radioFieldChanged: function radioFieldChanged(field, allFields, form) {
        get(this, 'radioFieldChanged')(field, allFields, form);
      }
    }
  });
});