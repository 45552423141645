define('account-opening/models/futures-trading-request', ['exports', 'twapi/models/futures-trading-request'], function (exports, _futuresTradingRequest) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _futuresTradingRequest.default;
    }
  });
});