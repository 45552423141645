define('account-opening/models/acat-institution', ['exports', 'twapi/models/acat-institution'], function (exports, _acatInstitution) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _acatInstitution.default;
    }
  });
});