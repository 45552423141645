define('account-opening/routes/step', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'account-opening/services/step'], function (exports, _authenticatedRouteMixin, _step) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      service = Ember.inject.service,
      Route = Ember.Route;
  exports.default = Route.extend(_authenticatedRouteMixin.default, {
    twapiEndpoints: service(),

    accountOpening: service(),

    service: service('step'),

    session: service(),

    beforeModel: function beforeModel() {
      var firstStep = get(this, 'service').stepFor(get(_step.STEP_VALUES, 'firstObject'));
      this.transitionTo(get(firstStep, 'route'));

      this._super.apply(this, arguments);
      // NOTE: attach external-id to kissmetrics AFTER authentication
      var tastyUserId = get(this, 'session.data.authenticated.data.user.external-id');
      _kmq.push(['identify', tastyUserId]);

      window.dataLayer.push({
        'event': 'page-view',
        'tasty-user-id': tastyUserId
      });
    },
    model: function model() {
      return get(this, 'accountOpening');
    }
  });
});