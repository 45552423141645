define('account-opening/components/number-mask', ['exports', 'ember-text-mask-addons/components/number-mask'], function (exports, _numberMask) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _numberMask.default;
    }
  });
});