define('account-opening/components/documents/skippable-document-upload', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      get = Ember.get;
  exports.default = Component.extend({
    actions: {
      promptSkipDocumentUpload: function promptSkipDocumentUpload() {
        get(this, 'promptSkipDocumentUpload')();
      }
    }
  });
});