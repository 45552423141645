define('account-opening/components/account/joint-type-section', ['exports', 'ember-decorators/object', 'twapi/utils/permitted-account-type', 'twapi/models/account-type'], function (exports, _object, _permittedAccountType, _accountType) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _dec3, _desc, _value, _obj;

  var Component = Ember.Component;
  exports.default = Component.extend((_dec = (0, _object.computed)('residencyPermittedAccountTypes.[]'), _dec2 = (0, _object.computed)('residencyPermittedAccountTypes.[]'), _dec3 = (0, _object.computed)('isWrosPermitted', 'isTenantsInCommonPermitted'), (_obj = {
    residencyPermittedAccountTypes: [],

    isWrosPermitted: function isWrosPermitted(permittedAccountTypes) {
      return _permittedAccountType.default.isPermitted(permittedAccountTypes, _accountType.JOINT_TYPES.WROS);
    },
    isTenantsInCommonPermitted: function isTenantsInCommonPermitted(permittedAccountTypes) {
      return _permittedAccountType.default.isPermitted(permittedAccountTypes, _accountType.JOINT_TYPES.TENANTS_IN_COMMON);
    },
    optionValues: function optionValues(isWrosPermitted, isTenantsInCommonPermitted) {
      var optionValues = [];

      if (isWrosPermitted) {
        optionValues.push(_accountType.JOINT_TYPES.WROS);
      }
      if (isTenantsInCommonPermitted) {
        optionValues.push(_accountType.JOINT_TYPES.TENANTS_IN_COMMON);
      }

      return optionValues;
    }
  }, (_applyDecoratedDescriptor(_obj, 'isWrosPermitted', [_dec], Object.getOwnPropertyDescriptor(_obj, 'isWrosPermitted'), _obj), _applyDecoratedDescriptor(_obj, 'isTenantsInCommonPermitted', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'isTenantsInCommonPermitted'), _obj), _applyDecoratedDescriptor(_obj, 'optionValues', [_dec3], Object.getOwnPropertyDescriptor(_obj, 'optionValues'), _obj)), _obj)));
});