define('account-opening/models/order-fill-leg', ['exports', 'twapi/models/order-fill-leg'], function (exports, _orderFillLeg) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _orderFillLeg.default;
    }
  });
});