define('account-opening/components/amb-form-checkbox', ['exports', 'ember-ambitious-forms/components/amb-form-checkbox'], function (exports, _ambFormCheckbox) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _ambFormCheckbox.default;
    }
  });
});