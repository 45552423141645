define('account-opening/authorizers/twucs-session', ['exports', 'ember-simple-auth/authorizers/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    authorize: function authorize(json, block) {
      if ('data' in json) {
        var accessToken = json.data['session-token'];
        if (!Ember.isEmpty(accessToken)) {
          block('Authorization', accessToken);
        }
      }
    }
  });
});