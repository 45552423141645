define('account-opening/components/account/entity-form', ['exports', 'ember-decorators/object', 'twapi/proxies/customer'], function (exports, _object, _customer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _desc, _value, _obj;

  var Component = Ember.Component,
      _Ember$computed = Ember.computed,
      bool = _Ember$computed.bool,
      readOnly = _Ember$computed.readOnly,
      get = Ember.get,
      service = Ember.inject.service;
  exports.default = Component.extend((_dec = (0, _object.computed)('accountOpening.{customer,customer.isLoaded}'), _dec2 = (0, _object.computed)('customerProxy'), (_obj = {
    accountOpening: service(),

    twapiEndpoints: service(),

    entity: readOnly('accountOpening.entity'),

    isTrust: readOnly('entity.isTrust'),

    hasEntities: bool('customerEntities.length'),

    customerProxy: function customerProxy(customer) {
      return _customer.default.create({ content: customer, endpoints: get(this, 'twapiEndpoints') });
    },
    customerEntities: function customerEntities(customerProxy) {
      return get(customerProxy, 'customerEntities');
    }
  }, (_applyDecoratedDescriptor(_obj, 'customerProxy', [_dec], Object.getOwnPropertyDescriptor(_obj, 'customerProxy'), _obj), _applyDecoratedDescriptor(_obj, 'customerEntities', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'customerEntities'), _obj)), _obj)));
});