define('account-opening/components/prompt-confirmation-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['confirmation-modal'],
    attributeBindings: ['testId:data-test-id'],
    testId: 'confirmation-modal',

    actions: {
      confirm: function confirm() {
        this.set('enabled', false);
      }
    }
  });
});