define('account-opening/models/distribution-constraint', ['exports', 'twapi/models/distribution-constraint'], function (exports, _distributionConstraint) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _distributionConstraint.default;
    }
  });
});