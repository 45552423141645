define('account-opening/routes/joint-continuation-signup', ['exports', 'twapi/mixins/reset-scroll-mixin', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _resetScrollMixin, _unauthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route,
      service = Ember.inject.service,
      set = Ember.set;
  exports.default = Route.extend(_resetScrollMixin.default, _unauthenticatedRouteMixin.default, {
    accountOpening: service(),

    routeIfAlreadyAuthenticated: '/',

    beforeModel: function beforeModel(_ref) {
      var _ref$queryParams = _ref.queryParams,
          applicationId = _ref$queryParams.applicationId,
          email = _ref$queryParams.email,
          firstName = _ref$queryParams.firstName;

      this._super.apply(this, arguments);

      var controller = this.controllerFor('joint-continuation-signup');

      set(this, 'accountOpening.applicationId', applicationId);
      set(controller, 'user.email', email);
      set(controller, 'firstName', firstName);
    }
  });
});