define('account-opening/components/trader/joint-trader-form', ['exports', 'ember-decorators/object'], function (exports, _object) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _desc, _value, _obj;

  var Component = Ember.Component,
      alias = Ember.computed.alias,
      get = Ember.get,
      service = Ember.inject.service;
  exports.default = Component.extend((_dec = (0, _object.computed)('accountOpening.customer.firstName'), (_obj = {
    accountOpening: service(),

    jointAccount: service(),

    jointAccountContinuation: service(),

    isTenantsInCommon: alias('application.accountType.isJointTenantsInCommon'),

    titleSuffix: function titleSuffix(firstName) {
      return '(' + firstName + ')';
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      get(this, 'jointAccount').initializePercentages();
    }
  }, (_applyDecoratedDescriptor(_obj, 'titleSuffix', [_dec], Object.getOwnPropertyDescriptor(_obj, 'titleSuffix'), _obj)), _obj)));
});