define('account-opening/instance-initializers/castle-publishable-key', ['exports', 'twapi/instance-initializers/castle-publishable-key'], function (exports, _castlePublishableKey) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function () {
      return _castlePublishableKey.initialize;
    }
  });
});