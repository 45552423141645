define('account-opening/components/documents/entity-document-upload', ['exports', 'twapi/utils/validation', 'twapi/utils/constant'], function (exports, _validation, _constant) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      readOnly = Ember.computed.readOnly,
      service = Ember.inject.service,
      set = Ember.set;
  exports.default = Component.extend({
    accountOpening: service(),

    isMargin: readOnly('accountOpening.application.isMargin'),

    proofOfEntityUploaded: false,

    entityAgreementUploaded: false,

    maxFileSize: _constant.MAX_FILE_SIZE,

    actions: {
      proofOfEntityUploading: function proofOfEntityUploading() {
        set(this, 'proofOfEntityUploaded', false);
      },
      entityAgreementUploading: function entityAgreementUploading() {
        set(this, 'entityAgreementUploaded', false);
      },
      proofOfEntityUploadSuccess: function proofOfEntityUploadSuccess() {
        set(this, 'proofOfEntityUploaded', true);
      },
      entityAgreementUploadSuccess: function entityAgreementUploadSuccess() {
        set(this, 'entityAgreementUploaded', true);
      }
    }
  }).reopen(_validation.build({
    proofOfEntityUploaded: {
      inclusion: {
        in: [true]
      }
    },
    entityAgreementUploaded: {
      inclusion: {
        in: [true]
      }
    }
  }));
});