define('account-opening/services/equifax', ['exports', 'ember-decorators/object'], function (exports, _object) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _desc, _value, _obj;

  var _Ember$computed = Ember.computed,
      alias = _Ember$computed.alias,
      and = _Ember$computed.and,
      bool = _Ember$computed.bool,
      not = _Ember$computed.not,
      get = Ember.get,
      service = Ember.inject.service,
      Service = Ember.Service,
      set = Ember.set;


  var COMPARE = 'eIDcompare';
  var VERIFIER = 'eIDverifier';

  exports.default = Service.extend((_dec = (0, _object.computed)('hasVerificationResult', 'hasQuiz'), (_obj = {
    accountOpening: service(),

    twapiEndpoints: service(),

    quiz: null,

    hasQuiz: bool('quiz'),

    comparisonResult: null,

    verificationResponse: null,

    verificationResult: null,

    existingResult: null,

    hasExistingResult: bool('existingResult'),

    hasNoExistingResult: alias('existingResult.isNew'),

    hasReviewed: false,

    hasNotReviewed: not('hasReviewed'),

    hasVerificationResult: bool('verificationResult'),

    hasNoVerificationResponse: not('verificationResponse'),

    showReviewPrompt: and('needsToReview', 'hasNotReviewed'),

    needsToReview: and('hasVerificationResult', 'verificationResult.isRejected', 'hasNoPendingOrApprovedApplication'),

    needsVerification: and('hasNoPendingOrApprovedApplication', 'hasNoVerificationResponse', 'hasNoExistingResult'),

    hasPendingOrApprovedApplication: alias('accountOpening.customer.hasPendingOrApprovedApplication'),

    hasNoPendingOrApprovedApplication: not('hasPendingOrApprovedApplication'),

    quizPending: function quizPending(hasVerificationResult, hasQuiz) {
      return !hasVerificationResult && hasQuiz;
    },


    questions: alias('quiz.questions'),

    fetchExistingResult: function fetchExistingResult() {
      var result = get(this, 'twapiEndpoints').getLastEquifaxResult();

      set(this, 'existingResult', result);

      return result;
    },
    checkCustomerIdentity: function checkCustomerIdentity() {
      if (get(this, 'needsVerification')) {
        this.submitVerification();
      } else {
        this.submitComparison();
      }
    },
    setHasReviewed: function setHasReviewed() {
      if (get(this, 'needsToReview')) {
        set(this, 'hasReviewed', true);
      }
    },
    submitComparison: function submitComparison() {
      get(this, 'twapiEndpoints').submitEquifaxInteraction({ clientType: COMPARE }).then(this._handleComparisonResponse.bind(this));
    },
    submitVerification: function submitVerification() {
      get(this, 'twapiEndpoints').submitEquifaxInteraction({ clientType: VERIFIER }).then(this._handleVerificationResponse.bind(this));
    },
    submitQuiz: function submitQuiz() {
      get(this, 'twapiEndpoints').submitEquifaxQuiz(get(this, 'quiz').serialize()).then(this._handleVerificationResponse.bind(this));
    },
    _handleComparisonResponse: function _handleComparisonResponse(result) {
      set(this, 'comparisonResult', get(result, 'completion'));
    },
    _handleVerificationResponse: function _handleVerificationResponse(result) {
      set(this, 'verificationResponse', result);
      set(this, 'verificationResult', get(result, 'completion'));

      if (get(this, 'verificationResponse.hasQuiz')) {
        set(this, 'quiz', get(result, 'quiz'));
      }
    }
  }, (_applyDecoratedDescriptor(_obj, 'quizPending', [_dec], Object.getOwnPropertyDescriptor(_obj, 'quizPending'), _obj)), _obj)));
});