define('account-opening/amb-form-plugins/ember-cp-validations', ['exports', 'ember-computed-indirect/utils/indirect'], function (exports, _indirect) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.Plugin = undefined;
  exports.autoLoad = autoLoad;


  var ALLOW_BLANK_FILTERS = ['exclusion', 'format', 'inclusion', 'length', 'numericality'];

  function autoLoad(appInstance) {
    var validator = appInstance.resolveRegistration('validator:presence');
    return validator && typeof validator.getDependentsFor === 'function';
  }

  var Plugin = exports.Plugin = Ember.Mixin.create({
    validationScope: Ember.computed.oneWay('scope'),

    errors: Ember.computed.mapBy('_rawErrors', 'message'),
    _rawErrors: (0, _indirect.default)('_rawErrorsKey'),
    _rawErrorsKey: Ember.computed('validationScope', 'fieldKey', function () {
      var validationScope = this.get('validationScope');
      var fieldKey = this.get('fieldKey');
      if (!validationScope || !fieldKey) {
        // There's no scope for the errors to exist so let's create a dummy version
        return '_rawErrorsLocal';
      }

      return 'validationScope.validations.attrs.' + fieldKey + '.errors.[]';
    }),

    _fieldValidationRules: Ember.computed('validationScope', 'fieldKey', function () {
      var fieldKey = this.get('fieldKey');
      return Ember.A(this.get('validationScope.validations._validationRules.' + fieldKey));
    }),

    required: Ember.computed('_fieldValidationRules', function () {
      var rules = this.get('_fieldValidationRules');
      for (var i = 0; i < rules.length; i++) {
        var rule = rules[i];
        if (ALLOW_BLANK_FILTERS.indexOf(rule._type) && !rule.options.allowBlank) {
          return true;
        }

        if (rule._type === 'presence') {
          return true;
        }
      }

      return false;
    }),

    optionValues: Ember.computed('validationScope', 'fieldKey', function () {
      var inclusion = this.get('_fieldValidationRules').findBy('_type', 'inclusion');
      return inclusion && inclusion.options.in || this._super();
    })
  });

  exports.default = { autoLoad: autoLoad, Plugin: Plugin };
});