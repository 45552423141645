define('account-opening/models/applicant-data', ['exports', 'twapi/models/applicant-data'], function (exports, _applicantData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _applicantData.default;
    }
  });
});