define('account-opening/models/account-beneficiary', ['exports', 'twapi/models/account-beneficiary'], function (exports, _accountBeneficiary) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _accountBeneficiary.default;
    }
  });
});