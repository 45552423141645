define('account-opening/routes/login', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _unauthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route,
      get = Ember.get,
      service = Ember.inject.service,
      set = Ember.set;
  exports.default = Route.extend(_unauthenticatedRouteMixin.default, {
    referral: service(),

    queryParams: {
      externalId: { refreshModel: true },
      hkey: { refreshModel: true },
      referralCode: { refreshModel: true }
    },

    model: function model(_ref) {
      var referralCode = _ref.referralCode,
          externalId = _ref.externalId,
          hkey = _ref.hkey;

      if (referralCode) {
        get(this, 'referral').setReferral(referralCode, externalId, hkey);
      }

      set(this.controllerFor('login'), 'pendingReferral', get(this, 'referral').getReferral());
    }
  });
});