define('account-opening/authenticators/twucs', ['exports', 'raven', 'ember-simple-auth/authenticators/base', 'twapi/models/session-response', 'twapi/utils/castle-token'], function (exports, _raven, _base, _sessionResponse, _castleToken) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var alias = Ember.computed.alias,
      get = Ember.get,
      service = Ember.inject.service,
      isEmpty = Ember.isEmpty,
      RSVP = Ember.RSVP,
      set = Ember.set;
  exports.default = _base.default.extend({
    session: service(),
    twapiClient: service(),
    twapiCookies: service(),
    twapiGtmEvents: service(),

    sessionsEndPoint: '/sessions',
    sessionsValidationPath: '/sessions/validate',
    sessionToken: alias('session.response.sessionToken'),
    externalUserId: alias('session.data.authenticated.data.user.external-id'),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      get(this, 'session').on('authenticationSucceeded', function () {
        get(_this, 'twapiCookies').setAccountOpening2Session();
        var externalUserId = get(_this, 'externalUserId');
        get(_this, 'twapiGtmEvents').createLoginEvent(externalUserId);
      });
    },
    restore: function restore(data) {
      if (data.hasOwnProperty('data')) {
        var sessionToken = data.data['session-token'];
        var rememberToken = data.data['remember-token'];
        if (!isEmpty(sessionToken) || !isEmpty(rememberToken)) {
          return this.restoreFromTokens(sessionToken, rememberToken, data);
        }
      }
      return RSVP.reject();
    },
    authenticate: function authenticate(params) {
      return this.signIn(params);
    },
    invalidate: function invalidate() {
      return this.signOut();
    },
    restoreFromTokens: function restoreFromTokens(sessionToken, rememberToken, data) {
      var _this2 = this;

      return this.validateSessionToken(sessionToken).then(function () {
        // we must resolve the old response if session token is still valid
        return _this2.handleUserSignInSuccess(data);
      }).catch(function (_error) {
        return _this2.checkRememberToken(rememberToken);
      });
    },
    validateSessionToken: function validateSessionToken(sessionToken) {
      return get(this, 'twapiClient').post(get(this, 'sessionsValidationPath'), {
        httpOptions: {
          headers: { 'Authorization': sessionToken }
        }
      });
    },
    signIn: function signIn(params) {
      var _this3 = this;

      return _castleToken.default.attachTokenAsPromise(params).then(function (params) {
        return get(_this3, 'twapiClient').post(get(_this3, 'sessionsEndPoint'), params).then(function (data) {
          return _this3.handleUserSignInSuccess(data);
        });
      });
    },
    checkRememberToken: function checkRememberToken(token) {
      return this.signIn({ 'remember-token': token });
    },
    setAuthorizationHeader: function setAuthorizationHeader(token) {
      set(this, 'twapiClient.headers.Authorization', token);
    },
    handleUserSignInSuccess: function handleUserSignInSuccess(json) {
      var sessionResponse = _sessionResponse.default.create({ isNew: false }).deserialize(json['data']);
      this.setAuthorizationHeader(get(sessionResponse, 'sessionToken'));
      set(this, 'session.response', sessionResponse);

      _raven.default.setUserContext({
        email: get(sessionResponse, 'user.email'),
        username: get(sessionResponse, 'user.username')
      });

      return json;
    },
    signOut: function signOut() {
      var _this4 = this;

      var response = get(this, 'twapiClient').del(get(this, 'sessionsEndPoint')).then(function () {
        var externalUserId = get(_this4, 'externalUserId');
        get(_this4, 'twapiGtmEvents').createLogoutEvent(externalUserId);
      }).catch(function (e) {
        // NOTE: [KT] already logged out if unauthorized/disconnected, only report unexpected statuses
        if (get(e, 'status') !== 401 && get(e, 'status') !== 0) {
          _raven.default.captureException(e);
        }
      }).finally(function () {
        _this4.setAuthorizationHeader(undefined);
        set(_this4, 'session.response', null);

        get(_this4, 'twapiCookies').setShowNewManagementMessage(true);
      });
      return response;
    }
  });
});