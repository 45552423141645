define('account-opening/components/amb-form-one-time-password', ['exports', 'twapi/components/amb-form-one-time-password'], function (exports, _ambFormOneTimePassword) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _ambFormOneTimePassword.default;
    }
  });
});