define('account-opening/components/prompt-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    closable: true,
    attributeBindings: ['dataTestId:data-test-id'],
    dataTestId: 'prompt-modal',

    actions: {
      toggleModal: function toggleModal() {
        this.toggleProperty('enabled');
      }
    }
  });
});