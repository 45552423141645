define('account-opening/models/autotrade-eligibility', ['exports', 'twapi/models/autotrade-eligibility'], function (exports, _autotradeEligibility) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _autotradeEligibility.default;
    }
  });
});