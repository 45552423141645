define('account-opening/components/form-section', ['exports', 'ember-decorators/object'], function (exports, _object) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _desc, _value, _obj;

  var Component = Ember.Component,
      oneWay = Ember.computed.oneWay,
      get = Ember.get,
      scheduleOnce = Ember.run.scheduleOnce,
      set = Ember.set;
  exports.default = Component.extend((_dec = (0, _object.computed)('activeSection', 'number'), (_obj = {
    classNames: 'form-section',
    classNameBindings: ['isActive::disable-descendants'],

    parent: null,
    activeSection: null,
    number: null,

    form: null,
    errorStateValue: null,

    scope: oneWay('parent.scope'),

    isActive: function isActive(activeSection, number) {
      return number <= activeSection;
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);

      scheduleOnce('afterRender', this, function () {
        var errorState = get(_this, 'form.errorState');

        if (errorState) {
          _this._checkCompletion(errorState);
        }
      });
    },


    actions: {
      errorStateChanged: function errorStateChanged(group, errorState) {
        set(this, 'form', group);
        set(this, 'errorStateValue', get(errorState, 'value'));

        this._checkCompletion(errorState);
      }
    },

    _checkCompletion: function _checkCompletion(errorState) {
      if (get(errorState, 'isNone') && get(this, 'isActive')) {
        this.sendAction('onComplete', this);
      } else {
        this.sendAction('onError', this);
      }
    }
  }, (_applyDecoratedDescriptor(_obj, 'isActive', [_dec], Object.getOwnPropertyDescriptor(_obj, 'isActive'), _obj)), _obj))).reopenClass({
    positionalParams: ['number']
  });
});