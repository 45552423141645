define('account-opening/components/amb-form-dateselect', ['exports', 'ember-decorators/object', 'ember-decorators/object/evented', 'lodash', 'moment'], function (exports, _object, _evented, _lodash, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.MAX_AGE = undefined;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _dec3, _desc, _value, _obj;

  var get = Ember.get;


  var LEAP_YEAR = (0, _moment.default)('2000-01-01');

  var MAX_AGE = exports.MAX_AGE = 125;

  exports.default = Ember.Component.extend((_dec = (0, _evented.on)('init'), _dec2 = (0, _object.observes)('date'), _dec3 = (0, _object.computed)('month'), (_obj = {
    tagName: 'span',
    classNames: ['amb-form-dateselect', 'row'],

    date: Ember.computed.alias('value'),

    _dateObserver: function _dateObserver() {
      var date = this.get('date');
      var year = '',
          month = '',
          day = '';

      if (date) {
        var _date$split = date.split('-');

        var _date$split2 = _slicedToArray(_date$split, 3);

        year = _date$split2[0];
        month = _date$split2[1];
        day = _date$split2[2];
      }
      this.setProperties({ year: year, month: month, day: day });
    },
    yearOptions: function yearOptions() {
      var currentYear = new Date().getFullYear();
      var latestYear = get(this, 'latestYear') || currentYear - 18;
      var earliestYear = get(this, 'earliestYear') || currentYear - MAX_AGE;
      return (0, _lodash.default)(latestYear).range(earliestYear - 1, -1).map(String).value();
    },
    monthOptions: function monthOptions() {
      return _moment.default.monthsShort().map(function (name, i) {
        var month = _lodash.default.padLeft(i + 1, 2, '0');
        var text = month + ' - ' + name;
        return [month, text];
      });
    },
    dayOptions: function dayOptions(month) {
      var endOfMonth = LEAP_YEAR.clone().month(month - 1).endOf('month');
      var numDays = endOfMonth.date() || 31;
      return (0, _lodash.default)(1).range(numDays + 1).map(function (num) {
        return _lodash.default.padLeft(num, 2, '0');
      }).value();
    },


    actions: {
      select: function select() {
        var year = this.get('year');
        var month = this.get('month');
        var day = this.get('day');

        if (year && month && day) {
          this.sendAction('action', [year, month, day].join('-'));
        }
      }
    }
  }, (_applyDecoratedDescriptor(_obj, '_dateObserver', [_dec, _dec2], Object.getOwnPropertyDescriptor(_obj, '_dateObserver'), _obj), _applyDecoratedDescriptor(_obj, 'yearOptions', [_object.computed], Object.getOwnPropertyDescriptor(_obj, 'yearOptions'), _obj), _applyDecoratedDescriptor(_obj, 'monthOptions', [_object.computed], Object.getOwnPropertyDescriptor(_obj, 'monthOptions'), _obj), _applyDecoratedDescriptor(_obj, 'dayOptions', [_dec3], Object.getOwnPropertyDescriptor(_obj, 'dayOptions'), _obj)), _obj)));
});