define('account-opening/components/account/residency-section', ['exports', 'twapi/models/address', 'twapi/utils/validation'], function (exports, _address, _validation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      _Ember$computed = Ember.computed,
      alias = _Ember$computed.alias,
      readOnly = _Ember$computed.readOnly,
      service = Ember.inject.service;
  exports.default = Component.extend({
    accountOpening: service(),

    foreignCountryOptions: _address.FOREIGN_ENTITY_OWNER_COUNTRIES_OPTIONS,

    customer: readOnly('accountOpening.customer'),

    country: alias('address.country'),

    address: alias('customer.address'),

    hasPendingOrApprovedApplication: readOnly('customer.hasPendingOrApprovedApplication')
  }).reopen(_validation.build({
    country: { presence: true }
  }));
});