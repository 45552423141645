define('account-opening/components/account/margin-section', ['exports', 'ember-decorators/object', 'twapi/utils/permitted-account-type', 'twapi/models/account-type'], function (exports, _object, _permittedAccountType, _accountType) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _dec3, _desc, _value, _obj;

  var Component = Ember.Component,
      readOnly = Ember.computed.readOnly,
      service = Ember.inject.service;
  exports.default = Component.extend((_dec = (0, _object.computed)('accountTypeName', 'entityType', 'isBusinessAccount', 'residencyPermittedAccountTypes.[]'), _dec2 = (0, _object.computed)('accountTypeName', 'entityType', 'isBusinessAccount', 'residencyPermittedAccountTypes.[]'), _dec3 = (0, _object.computed)('isMarginPermitted', 'isCashPermitted'), (_obj = {
    accountOpening: service(),

    residencyPermittedAccountTypes: [],

    entityType: null,

    accountTypeName: readOnly('accountOpening.application.accountTypeName'),

    isBusinessAccount: readOnly('accountOpening.isBusinessAccount'),

    isMarginPermitted: function isMarginPermitted(accountTypeName, entityType, isBusinessAccount, permittedAccountTypes) {
      var marginType = _accountType.MARGIN_TYPES.MARGIN;

      if (isBusinessAccount) {
        return _permittedAccountType.default.isPermitted(permittedAccountTypes, accountTypeName, entityType, marginType);
      }

      return _permittedAccountType.default.isPermitted(permittedAccountTypes, accountTypeName, marginType);
    },
    isCashPermitted: function isCashPermitted(accountTypeName, entityType, isBusinessAccount, permittedAccountTypes) {
      var marginType = _accountType.MARGIN_TYPES.CASH;

      if (isBusinessAccount) {
        return _permittedAccountType.default.isPermitted(permittedAccountTypes, accountTypeName, entityType, marginType);
      }

      return _permittedAccountType.default.isPermitted(permittedAccountTypes, accountTypeName, marginType);
    },
    optionValues: function optionValues(isMarginPermitted, isCashPermitted) {
      var optionValues = [];

      if (isMarginPermitted) {
        optionValues.push(_accountType.MARGIN_TYPES.MARGIN);
      }
      if (isCashPermitted) {
        optionValues.push(_accountType.MARGIN_TYPES.CASH);
      }

      return optionValues;
    }
  }, (_applyDecoratedDescriptor(_obj, 'isMarginPermitted', [_dec], Object.getOwnPropertyDescriptor(_obj, 'isMarginPermitted'), _obj), _applyDecoratedDescriptor(_obj, 'isCashPermitted', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'isCashPermitted'), _obj), _applyDecoratedDescriptor(_obj, 'optionValues', [_dec3], Object.getOwnPropertyDescriptor(_obj, 'optionValues'), _obj)), _obj)));
});