define('account-opening/router', ['exports', 'account-opening/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('login');

    this.route('step', function () {
      this.route('account');
      this.route('trader');
      this.route('access');
      this.route('security');
      this.route('documents');
      this.route('review');
    });

    this.route('document-verification');
    this.route('done');
    this.route('joint-continuation-signup');
    this.route('referred-prospects');
  });

  exports.default = Router;
});