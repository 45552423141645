define('account-opening/components/twapi-model-state-override', ['exports', 'twapi/components/twapi-model-state-override'], function (exports, _twapiModelStateOverride) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _twapiModelStateOverride.default;
    }
  });
});