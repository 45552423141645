define('account-opening/services/twapi-plan-access', ['exports', 'twapi/services/twapi-plan-access'], function (exports, _twapiPlanAccess) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _twapiPlanAccess.default;
    }
  });
});