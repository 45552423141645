define('account-opening/routes/referred-prospects', ['exports', 'twapi/mixins/reset-scroll-mixin', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _resetScrollMixin, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route,
      get = Ember.get,
      service = Ember.inject.service;
  exports.default = Route.extend(_resetScrollMixin.default, _authenticatedRouteMixin.default, {
    accountOpening: service(),

    referral: service(),

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      if (!get(this, 'referral.hasAnyProspects')) {
        this.transitionTo('step');
      }
    },
    model: function model() {
      return get(this, 'accountOpening.customer');
    }
  });
});