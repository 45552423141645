define('account-opening/components/trader/trader-info', ['exports', 'ember-decorators/object'], function (exports, _object) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _desc, _value, _obj;

  var Component = Ember.Component,
      _Ember$computed = Ember.computed,
      alias = _Ember$computed.alias,
      readOnly = _Ember$computed.readOnly,
      _get = Ember.get,
      service = Ember.inject.service,
      _set = Ember.set;
  exports.default = Component.extend((_dec = (0, _object.computed)('isForeignCitizenSelected', 'customer.{address.isDomestic,isPermanentResident}'), (_obj = {
    accountOpening: service(),

    equifax: service(),

    application: alias('accountOpening.application'),

    customer: alias('accountOpening.customer'),

    entity: alias('accountOpening.entity'),

    showReviewPrompt: readOnly('equifax.showReviewPrompt'),

    isForeignCitizenAndResidence: readOnly('accountOpening.isForeignCitizenAndResidence'),

    isForeignCitizenSelected: readOnly('accountOpening.isForeignCitizenSelected'),

    // NOTE: using hand-rolled alias to allow custom validation
    isUsCitizen: Ember.computed('customer.isUsCitizen', {
      get: function get(key) {
        return _get(_get(this, 'customer'), 'isUsCitizen');
      },
      set: function set(key, value) {
        var customer = _get(this, 'customer');
        var toReturn = _set(customer, 'isUsCitizen', value);

        customer.trigger('didUpdate');

        return toReturn;
      }
    }),
    requiresVisa: function requiresVisa(isForeignCitizenSelected, addressIsDomestic, isPermanentResident) {
      return isForeignCitizenSelected && addressIsDomestic && isPermanentResident === false;
    },


    actions: {
      formSubmit: function formSubmit(step) {
        this.sendAction('action', step);
      },
      checkForeignApplicant: function checkForeignApplicant() {
        if (_get(this, 'isForeignCitizenAndResidence')) {
          window.scrollTo(0, 0);
        }
      }
    }
  }, (_applyDecoratedDescriptor(_obj, 'requiresVisa', [_dec], Object.getOwnPropertyDescriptor(_obj, 'requiresVisa'), _obj)), _obj)));
});