define('account-opening/utils/mobile-notification', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.notifyAccountOpeningApplicationComplete = notifyAccountOpeningApplicationComplete;
  var isPresent = Ember.isPresent;


  function getIosPostMessage() {
    var messageHandler = _lodash.default.get(window, 'webkit.messageHandlers.notifyAccountOpeningApplicationComplete');

    if (isPresent(messageHandler) && isPresent(messageHandler.postMessage)) {
      return messageHandler.postMessage.bind(messageHandler);
    }

    return null;
  }

  function getAndroidPostMessage() {
    var androidInterface = _lodash.default.get(window, 'Android');

    if (isPresent(androidInterface) && isPresent(androidInterface.notifyAccountOpeningApplicationComplete)) {
      return androidInterface.notifyAccountOpeningApplicationComplete.bind(androidInterface);
    }

    return null;
  }

  function notifyAccountOpeningApplicationComplete(sessionToken) {
    var postMessage = void 0;

    if (isPresent(postMessage = getIosPostMessage())) {
      postMessage(sessionToken);
    } else if (isPresent(postMessage = getAndroidPostMessage())) {
      postMessage(sessionToken);
    }
  }
});