define('account-opening/components/trader/entity-relationship-section', ['exports', 'twapi/models/entity', 'twapi/utils/validation'], function (exports, _entity, _validation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      alias = Ember.computed.alias,
      service = Ember.inject.service;
  exports.default = Component.extend({
    accountOpening: service(),

    jobTitle: alias('accountOpening.customerSuitability.jobTitle'),

    relationshipToEntity: alias('accountOpening.relationshipToEntity')
  }).reopen(_validation.build({
    jobTitle: {
      presence: true,
      inclusion: { in: Object.values(_entity.JOB_TITLES) }
    },
    relationshipToEntity: {
      presence: true,
      inclusion: { in: Object.values(_entity.RELATIONSHIP_TYPES) }
    }
  }));
});