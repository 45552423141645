define('account-opening/components/amb-form-datepicker', ['exports', 'ember-decorators/object', 'twapi/utils/date-format', 'moment'], function (exports, _object, _dateFormat, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _desc, _value, _obj;

  var Component = Ember.Component,
      get = Ember.get,
      isNone = Ember.isNone,
      set = Ember.set;
  exports.default = Component.extend((_dec = (0, _object.computed)('value'), (_obj = {
    classNames: ['amb-form-datepicker'],

    value: null,

    formattedDate: function formattedDate(value) {
      if (isNone(value)) {
        return null;
      }

      return value.format(_dateFormat.DATE_FORMAT);
    },
    change: function change(event) {
      var target = get(event, 'target');
      var inputDate = get(target, 'value');
      set(this, 'value', (0, _moment.default)(inputDate));
    }
  }, (_applyDecoratedDescriptor(_obj, 'formattedDate', [_dec], Object.getOwnPropertyDescriptor(_obj, 'formattedDate'), _obj)), _obj)));
});