define('account-opening/components/account/retirement-section', ['exports', 'ember-decorators/object', 'twapi/utils/permitted-account-type', 'twapi/models/account-type'], function (exports, _object, _permittedAccountType, _accountType) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _desc, _value, _obj;

  var Component = Ember.Component;
  exports.default = Component.extend((_dec = (0, _object.computed)('residencyPermittedAccountTypes.[]'), _dec2 = (0, _object.computed)('residencyPermittedAccountTypes.[]'), _dec3 = (0, _object.computed)('residencyPermittedAccountTypes.[]'), _dec4 = (0, _object.computed)('residencyPermittedAccountTypes.[]'), _dec5 = (0, _object.computed)('residencyPermittedAccountTypes.[]'), _dec6 = (0, _object.computed)('isTraditionalIraPermitted', 'isRothIraPermitted', 'isSepIraPermitted', 'isBeneficiaryTraditionalIraPermitted', 'isBeneficiaryRothIraPermitted'), (_obj = {
    isTraditionalIraPermitted: function isTraditionalIraPermitted(permittedAccountTypes) {
      return _permittedAccountType.default.isPermitted(permittedAccountTypes, _accountType.IRA_TYPES.TRADITIONAL_IRA);
    },
    isRothIraPermitted: function isRothIraPermitted(permittedAccountTypes) {
      return _permittedAccountType.default.isPermitted(permittedAccountTypes, _accountType.IRA_TYPES.ROTH_IRA);
    },
    isSepIraPermitted: function isSepIraPermitted(permittedAccountTypes) {
      return _permittedAccountType.default.isPermitted(permittedAccountTypes, _accountType.IRA_TYPES.SEP_IRA);
    },
    isBeneficiaryTraditionalIraPermitted: function isBeneficiaryTraditionalIraPermitted(permittedAccountTypes) {
      return _permittedAccountType.default.isPermitted(permittedAccountTypes, _accountType.IRA_TYPES.BENEFICIARY_TRADITIONAL_IRA);
    },
    isBeneficiaryRothIraPermitted: function isBeneficiaryRothIraPermitted(permittedAccountTypes) {
      return _permittedAccountType.default.isPermitted(permittedAccountTypes, _accountType.IRA_TYPES.BENEFICIARY_ROTH_IRA);
    },
    optionValues: function optionValues(isTraditionalIraPermitted, isRothIraPermitted, isSepIraPermitted, isBeneficiaryTraditionalIraPermitted, isBeneficiaryRothIraPermitted) {
      var optionValues = [];

      if (isTraditionalIraPermitted) {
        optionValues.push(_accountType.IRA_TYPES.TRADITIONAL_IRA);
      }
      if (isRothIraPermitted) {
        optionValues.push(_accountType.IRA_TYPES.ROTH_IRA);
      }
      if (isSepIraPermitted) {
        optionValues.push(_accountType.IRA_TYPES.SEP_IRA);
      }
      if (isBeneficiaryTraditionalIraPermitted) {
        optionValues.push(_accountType.IRA_TYPES.BENEFICIARY_TRADITIONAL_IRA);
      }
      if (isBeneficiaryRothIraPermitted) {
        optionValues.push(_accountType.IRA_TYPES.BENEFICIARY_ROTH_IRA);
      }

      return optionValues;
    }
  }, (_applyDecoratedDescriptor(_obj, 'isTraditionalIraPermitted', [_dec], Object.getOwnPropertyDescriptor(_obj, 'isTraditionalIraPermitted'), _obj), _applyDecoratedDescriptor(_obj, 'isRothIraPermitted', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'isRothIraPermitted'), _obj), _applyDecoratedDescriptor(_obj, 'isSepIraPermitted', [_dec3], Object.getOwnPropertyDescriptor(_obj, 'isSepIraPermitted'), _obj), _applyDecoratedDescriptor(_obj, 'isBeneficiaryTraditionalIraPermitted', [_dec4], Object.getOwnPropertyDescriptor(_obj, 'isBeneficiaryTraditionalIraPermitted'), _obj), _applyDecoratedDescriptor(_obj, 'isBeneficiaryRothIraPermitted', [_dec5], Object.getOwnPropertyDescriptor(_obj, 'isBeneficiaryRothIraPermitted'), _obj), _applyDecoratedDescriptor(_obj, 'optionValues', [_dec6], Object.getOwnPropertyDescriptor(_obj, 'optionValues'), _obj)), _obj)));
});