define('account-opening/components/documents/foreign-documents-form', ['exports', 'twapi/mixins/document-verification-upload', 'twapi/utils/validation'], function (exports, _documentVerificationUpload, _validation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      _Ember$computed = Ember.computed,
      or = _Ember$computed.or,
      readOnly = _Ember$computed.readOnly,
      service = Ember.inject.service;
  exports.default = Component.extend(_documentVerificationUpload.default, {
    accountOpening: service(),

    citizenshipCountry: readOnly('accountOpening.customer.citizenshipCountry'),

    hasCompletedIdVerification: false,

    hasCompletedAddressVerification: false,

    skippedIdUpload: null,

    skippedAddressUpload: null,

    isIdSectionComplete: or('idUploaded', 'hasCompletedIdVerification', 'skippedIdUpload'),

    isAddressSectionComplete: or('addressUploaded', 'hasCompletedAddressVerification', 'skippedAddressUpload')
  }).reopen(_validation.build({
    isIdSectionComplete: { inclusion: { in: [true] } },
    isAddressSectionComplete: { inclusion: { in: [true] } }
  }));
});