define('account-opening/services/plan-access', ['exports', 'ember-decorators/object', 'lodash', 'twapi/models/access-plan'], function (exports, _object, _lodash, _accessPlan) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _desc, _value, _obj;

  var idealCustomer = {
    stockTradingExperience: 'EXTENSIVE',
    coveredOptionsTradingExperience: 'EXTENSIVE',
    uncoveredOptionsTradingExperience: 'EXTENSIVE',
    futuresTradingExperience: 'EXTENSIVE',
    investmentObjective: 'SPECULATION',
    annualNetIncome: 1200001,
    netWorth: 5000001,
    liquidNetWorth: 5000001
  };

  var PLAN_NAMES = {
    WORKS: 'The Works',
    BASIC: 'Basic',
    LIMITED: 'Limited'
  };

  var MARGIN_TYPES = {
    MARGIN: 'Margin',
    CASH: 'Cash',
    RETIREMENT: 'Retirement'
  };

  function asDirective(value) {
    return {
      value: value,
      property: 'has' + value.classify()
    };
  }

  exports.default = Ember.Service.extend((_dec = (0, _object.computed)('application.accountType'), _dec2 = (0, _object.computed)('application.{accountType.isAnyIra,isMargin}'), _dec3 = (0, _object.computed)('marginType', 'application.accountType'), _dec4 = (0, _object.computed)('optionsLevel', 'eligiblePlansForMarginType'), _dec5 = (0, _object.computed)('optionsLevel', 'eligiblePlansForMarginType', 'application.accountType'), _dec6 = (0, _object.computed)('currentPlan', 'recommendedPlan', 'optionsLevel'), _dec7 = (0, _object.computed)('needsReview'), (_obj = {
    twapiClient: Ember.inject.service(),
    accountOpening: Ember.inject.service(),

    optionsLevel: 'No Restrictions',

    allPlans: function allPlans(accountType) {
      return _accessPlan.default.all(accountType);
    },


    application: Ember.computed.alias('accountOpening.application'),
    customerSuitability: Ember.computed.alias('accountOpening.customerSuitability'),
    currentPlan: Ember.computed.alias('accountOpening.application.accessPlan'),

    permissionGroups: [_lodash.default.map(_accessPlan.EQUITY_PERMISSIONS, asDirective), _lodash.default.map(_accessPlan.DERIVATIVE_PERMISSIONS, asDirective)],

    marginTypes: MARGIN_TYPES,

    marginType: function marginType(isAnyIra, isMargin) {
      if (isAnyIra) {
        return MARGIN_TYPES.RETIREMENT;
      }

      if (isMargin) {
        return MARGIN_TYPES.MARGIN;
      }

      return MARGIN_TYPES.CASH;
    },
    eligiblePlansForMarginType: function eligiblePlansForMarginType(marginType, accountType) {
      switch (marginType) {
        case MARGIN_TYPES.MARGIN:
          return this.get('allPlans');
        case MARGIN_TYPES.RETIREMENT:
          return [_accessPlan.BasicPlan.create({ accountType: accountType }), _accessPlan.LimitedPlan.create({ accountType: accountType })];
        case MARGIN_TYPES.CASH:
          return [_accessPlan.LimitedPlan.create({ accountType: accountType })];
        default:
          return [_accessPlan.LimitedPlan.create({ accountType: accountType })];
      }
    },
    recommendedPlan: function recommendedPlan(optionsLevel, eligiblePlans) {
      switch (optionsLevel) {
        case 'No Restrictions':
          // return highest eligible plan for margin type
          return eligiblePlans[0];
        case 'Defined Risk Spreads':
          // return basic if available, otherwise limited
          var basic = eligiblePlans.find(function (plan) {
            return plan.name === PLAN_NAMES.BASIC;
          });
          if (basic) return basic;
          return eligiblePlans.find(function (plan) {
            return plan.name === PLAN_NAMES.LIMITED;
          });
        default:
          return eligiblePlans.find(function (plan) {
            return plan.name === PLAN_NAMES.LIMITED;
          });
      }
    },
    allowedPlans: function allowedPlans(optionsLevel, eligiblePlansForMarginType, accountType) {
      var plans = [];
      switch (optionsLevel) {/* eslint no-fallthrough: "off" */
        case 'No Restrictions':
          plans.push(_accessPlan.TheWorksPlan.create({ accountType: accountType }));
        case 'Defined Risk Spreads':
          plans.push(_accessPlan.BasicPlan.create({ accountType: accountType }));
        default:
          plans.push(_accessPlan.LimitedPlan.create({ accountType: accountType }));
      }
      plans = plans.filter(function (plan) {
        return eligiblePlansForMarginType.some(function (p) {
          return p.name === plan.name;
        });
      });
      return plans;
    },
    planName: function planName(plan) {
      return plan.get('name');
    },
    pullSuitabilityParams: function pullSuitabilityParams() {
      var customerSuitability = this.get('customerSuitability');
      var relevantParams = ['stockTradingExperience', 'coveredOptionsTradingExperience', 'uncoveredOptionsTradingExperience', 'futuresTradingExperience', 'annualNetIncome', 'netWorth', 'liquidNetWorth'];
      var params = customerSuitability.getProperties.apply(customerSuitability, relevantParams);
      params.investmentObjective = this.get('application.investmentObjective');
      // Filter undefined params
      Object.keys(params).map(function (key) {
        var val = params[key];
        if (Ember.isNone(val)) delete params[key];
      });
      return params;
    },
    needsReview: function needsReview(currentPlan, recommendedPlan) {
      return currentPlan.get('name') !== recommendedPlan.get('name');
    },
    reasonForReview: function reasonForReview() {
      var needsReview = this.get('needsReview');
      if (!needsReview) return null;

      var planName = this.get('currentPlan.name');
      var suitabilityParams = this.pullSuitabilityParams();
      switch (this.get('marginType')) {
        case MARGIN_TYPES.MARGIN:
          switch (planName) {
            case PLAN_NAMES.WORKS:
              if (suitabilityParams.coveredOptionsTradingExperience !== 'EXTENSIVE') {
                return 'The Works plan requires extensive knowledge of covered options and spreads.';
              } else if (suitabilityParams.uncoveredOptionsTradingExperience !== 'EXTENSIVE') {
                return 'The Works plan requires extensive knowledge of uncovered options.';
              } else if (suitabilityParams.investmentObjective === 'INCOME' || suitabilityParams.investmentObjective === 'CAPITAL_PRESERVATION') {
                return 'The Works plan requires a trading objective of speculation or growth.';
              } else if (suitabilityParams.liquidNetWorth === 0) {
                return 'The Works plan requires liquid net worth or annual income to be greater than $50k.';
              }
              return MARGIN_TYPES.MARGIN;
            default:
              return '';
          }
        case MARGIN_TYPES.CASH:
          return 'Cash accounts are only allowed \'Limited\' access at this time.';
        case MARGIN_TYPES.RETIREMENT:
          switch (planName) {
            case PLAN_NAMES.BASIC:
              if (suitabilityParams.coveredOptionsTradingExperience === 'LIMITED') {
                return 'The Basic plan requires good or extensive knowledge of covered options and spreads.';
              } else if (suitabilityParams.investmentObjective === 'INCOME' || suitabilityParams.investmentObjective === 'CAPITAL_PRESERVATION') {
                return 'The Basic plan requires a trading objective of speculation, growth, or income.';
              }
              return '';
            default:
              return '';
          }
        default:
          return '';
      }
    },
    updateOptionsLevel: function updateOptionsLevel(plan) {
      var _this = this;

      var customerSuitability = Object.assign({}, idealCustomer, this.pullSuitabilityParams());
      return this.get('twapiClient').post('/determine-options-level', customerSuitability).then(function (res) {
        _this.set('optionsLevel', res.data['options-level']);
      });
    }
  }, (_applyDecoratedDescriptor(_obj, 'allPlans', [_dec], Object.getOwnPropertyDescriptor(_obj, 'allPlans'), _obj), _applyDecoratedDescriptor(_obj, 'marginType', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'marginType'), _obj), _applyDecoratedDescriptor(_obj, 'eligiblePlansForMarginType', [_dec3], Object.getOwnPropertyDescriptor(_obj, 'eligiblePlansForMarginType'), _obj), _applyDecoratedDescriptor(_obj, 'recommendedPlan', [_dec4], Object.getOwnPropertyDescriptor(_obj, 'recommendedPlan'), _obj), _applyDecoratedDescriptor(_obj, 'allowedPlans', [_dec5], Object.getOwnPropertyDescriptor(_obj, 'allowedPlans'), _obj), _applyDecoratedDescriptor(_obj, 'needsReview', [_dec6], Object.getOwnPropertyDescriptor(_obj, 'needsReview'), _obj), _applyDecoratedDescriptor(_obj, 'reasonForReview', [_dec7], Object.getOwnPropertyDescriptor(_obj, 'reasonForReview'), _obj)), _obj)));
});