define('account-opening/components/step-form', ['exports', 'ember-decorators/object'], function (exports, _object) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _desc, _value, _obj;

  exports.default = Ember.Component.extend((_dec = (0, _object.computed)('id'), (_obj = {
    service: Ember.inject.service('step'),

    id: null,

    activeSection: 1,

    step: function step(id) {
      return this.get('service').stepFor(id);
    },
    updateViaForm: function updateViaForm(ambForm) {
      var step = this.get('step');
      step.beginPropertyChanges();
      // It is possible for the form to remove fields.
      // `addObjects` will preserve all the fieldNames ever added.
      step.get('fieldNames').addObjects(ambForm.get('fields').mapBy('fieldKey'));
      step.set('isComplete', !ambForm.get('hasErrors'));
      step.endPropertyChanges();
    },


    actions: {
      errorsChanged: function errorsChanged(ambForm) {
        this.updateViaForm(ambForm);
      },
      submit: function submit(ambForm) {
        this.updateViaForm(ambForm);
        this.sendAction('onSubmit', this.get('step'));
      },
      sectionComplete: function sectionComplete(section) {
        var number = section.get('number');
        if (number >= this.get('activeSection')) {
          this.set('activeSection', number + 1);
        }
      }
    }
  }, (_applyDecoratedDescriptor(_obj, 'step', [_dec], Object.getOwnPropertyDescriptor(_obj, 'step'), _obj)), _obj))).reopenClass({
    positionalParams: ['id']
  });
});