define('account-opening/amb-form-plugins/loc', ['exports', 'ember-ambitious-forms/mixins/lookup'], function (exports, _lookup2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.Plugin = undefined;
  exports.autoLoad = autoLoad;
  function autoLoad(_appInstance) {
    for (var key in Ember.STRINGS) {
      if (Ember.STRINGS.hasOwnProperty(key) && key.indexOf('amb-form.') === 0) {
        return true;
      }
    }

    return false;
  }

  var Plugin = exports.Plugin = Ember.Mixin.create(_lookup2.default, {
    _lookup: function _lookup(key, args) {
      return Ember.String.loc(key, args);
    },
    _lookupExists: function _lookupExists(key) {
      // TODO: remove dependency on internal magic
      return Ember.STRINGS.hasOwnProperty(key);
    }
  });

  exports.default = { autoLoad: autoLoad, Plugin: Plugin };
});