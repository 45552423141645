define('account-opening/services/step', ['exports', 'ember-decorators/object'], function (exports, _object) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.STEP_VALUES = exports.REVIEW_STEP = exports.TRADER_STEP = exports.ACCOUNT_STEP = undefined;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _desc, _value, _obj, _dec3, _desc2, _value2, _obj2;

  var Service = Ember.Service,
      service = Ember.inject.service;
  var ACCOUNT_STEP = exports.ACCOUNT_STEP = 'account';
  var TRADER_STEP = exports.TRADER_STEP = 'trader';
  var REVIEW_STEP = exports.REVIEW_STEP = 'review';

  var STEP_VALUES = exports.STEP_VALUES = [ACCOUNT_STEP, TRADER_STEP, 'access', 'security', 'documents', REVIEW_STEP];

  var Step = Ember.Object.extend((_dec = (0, _object.computed)('id'), _dec2 = (0, _object.computed)('id'), (_obj = {
    id: null,
    fieldNames: Ember.computed(function () {
      return Ember.A();
    }),
    isComplete: false,

    display: function display(id) {
      return id.capitalize();
    },
    route: function route(id) {
      return 'step.' + id;
    }
  }, (_applyDecoratedDescriptor(_obj, 'display', [_dec], Object.getOwnPropertyDescriptor(_obj, 'display'), _obj), _applyDecoratedDescriptor(_obj, 'route', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'route'), _obj)), _obj)));

  exports.default = Service.extend((_dec3 = (0, _object.computed)('all.@each.isComplete'), (_obj2 = {
    i18n: service(),

    equifax: service(),

    all: Ember.computed(function () {
      return STEP_VALUES.map(function (id) {
        return Step.create({ id: id });
      });
    }),

    nextIncompleteStep: function nextIncompleteStep(all) {
      return all.findBy('isComplete', false);
    },
    nextStep: function nextStep(step) {
      var all = this.get('all');
      if (this.get('equifax.showReviewPrompt')) {
        return this.stepFor(TRADER_STEP);
      }

      var next = all.indexOf(step) + 1;
      return next < all.length ? all[next] : null;
    },
    stepFor: function stepFor(id) {
      return this.get('all').findBy('id', id);
    },
    stepForFieldNames: function stepForFieldNames(fieldNames) {
      return this.get('all').find(function (step) {
        var stepFields = step.get('fieldNames');
        return stepFields && fieldNames.any(function (name) {
          return stepFields.contains(name);
        });
      });
    }
  }, (_applyDecoratedDescriptor(_obj2, 'nextIncompleteStep', [_dec3], Object.getOwnPropertyDescriptor(_obj2, 'nextIncompleteStep'), _obj2)), _obj2)));
});