define('account-opening/components/access/knowledge-section', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      get = Ember.get;
  exports.default = Component.extend({
    actions: {
      fieldChanged: function fieldChanged(field, allFields, form) {
        get(this, 'fieldChanged')(field, allFields, form);
      }
    }
  });
});