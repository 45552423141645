define('account-opening/routes/step/account', ['exports', 'twapi/models/account-type', 'twapi/mixins/reset-scroll-mixin'], function (exports, _accountType, _resetScrollMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route,
      get = Ember.get,
      service = Ember.inject.service;
  exports.default = Route.extend(_resetScrollMixin.default, {
    accountOpening: service(),

    step: service(),

    twapiEndpoints: service(),

    model: function model() {
      return Ember.RSVP.hash({
        application: get(this, 'accountOpening.accountOpeningApplication.promise'),
        accountTypes: _accountType.default.findAll(),
        customer: get(this, 'accountOpening.customerProxy.promise')
      });
    }
  });
});