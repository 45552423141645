define('account-opening/amb-form-plugins/ember-validations', ['exports', 'ember-computed-indirect/utils/indirect'], function (exports, _indirect) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.Plugin = undefined;
  exports.autoLoad = autoLoad;


  var ALLOW_BLANK_FILTERS = ['exclusion', 'format', 'inclusion', 'length', 'numericality'];

  function autoLoad(appInstance) {
    return appInstance.hasRegistration('service:validations');
  }

  var Plugin = exports.Plugin = Ember.Mixin.create({
    validationScope: Ember.computed.oneWay('scope'),

    errors: (0, _indirect.default)('_errorsKey'),
    _errorsKey: Ember.computed('validationScope', 'fieldKey', function () {
      var validationScope = this.get('validationScope');
      var fieldKey = this.get('fieldKey');
      if (!validationScope || !fieldKey) {
        // There's no scope for the errors to exist so let's create a dummy version
        return '_errors';
      }

      return 'validationScope.errors.' + this.get('fieldKey') + '.[]';
    }),

    required: Ember.computed('validationScope', 'fieldKey', function () {
      var validationScope = this.get('validationScope');
      var fieldKey = this.get('fieldKey');
      if (!validationScope || !fieldKey) {
        return;
      }

      for (var i = 0; i < ALLOW_BLANK_FILTERS.length; i++) {
        var filterName = ALLOW_BLANK_FILTERS[i];
        var filter = validationScope.get('validations.' + fieldKey + '.' + filterName);
        if (filter) {
          return !filter.allowBlank;
        }
      }

      return Boolean(validationScope.get('validations.' + fieldKey + '.presence')) || Boolean(validationScope.get('validations.' + fieldKey + '.acceptance'));
    }),

    optionValues: Ember.computed('validationScope', 'fieldKey', function () {
      var validationScope = this.get('validationScope');
      var fieldKey = this.get('fieldKey');
      if (!validationScope || !fieldKey) {
        return this._super();
      }

      return this._super() || validationScope.get('validations.' + fieldKey + '.inclusion.in');
    })
  });

  exports.default = { autoLoad: autoLoad, Plugin: Plugin };
});