define('account-opening/components/confirm-leave-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      set = Ember.set;
  exports.default = Component.extend({
    enabled: false,

    actions: {
      confirmLeave: function confirmLeave() {
        window.location.href = 'https://tastytrade.com';
      },
      hideModal: function hideModal() {
        set(this, 'enabled', false);
      }
    }
  });
});