define('account-opening/components/forms/twapi-state-region-postal-fields', ['exports', 'twapi/components/forms/twapi-state-region-postal-fields'], function (exports, _twapiStateRegionPostalFields) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _twapiStateRegionPostalFields.default;
    }
  });
});