define('account-opening/models/tax-event', ['exports', 'twapi/models/tax-event'], function (exports, _taxEvent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _taxEvent.default;
    }
  });
});