define('account-opening/models/account-opening-application', ['exports', 'twapi/models/account-opening-application'], function (exports, _accountOpeningApplication) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _accountOpeningApplication.default;
    }
  });
});