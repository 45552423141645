define('account-opening/components/trader/contact-body', ['exports', 'ember-decorators/object', 'twapi/models/address'], function (exports, _object, _address) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _desc, _value, _obj;

  var Component = Ember.Component,
      _Ember$computed = Ember.computed,
      alias = _Ember$computed.alias,
      bool = _Ember$computed.bool,
      readOnly = _Ember$computed.readOnly,
      get = Ember.get,
      service = Ember.inject.service,
      set = Ember.set;
  exports.default = Component.extend((_dec = (0, _object.computed)('hasPendingOrApprovedApplication', 'twapiTwoFactor.isEnabled', 'customer.isNew'), _dec2 = (0, _object.computed)('isEntityOwner'), (_obj = {
    accountOpening: service(),

    twapiTwoFactor: service(),

    hasPendingOrApprovedApplication: readOnly('customer.hasPendingOrApprovedApplication'),

    isEntityOwner: bool('entity'),

    isForeign: readOnly('accountOpening.customer.isForeign'),

    address: alias('customer.address'),

    isAddressDomestic: bool('address.isDomestic'),

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (get(this, 'isEntityOwner')) {
        set(this, 'customer.address.isEntityOwnerAddress', true);
      } else {
        set(this, 'customer.address.isEntityOwnerAddress', false);
      }
    },
    isMobileReadOnly: function isMobileReadOnly(hasPendingOrApprovedApplication, isTwoFactorEnabled, isNew) {
      if (isNew) {
        return false;
      }

      return hasPendingOrApprovedApplication || isTwoFactorEnabled;
    },
    foreignCountryOptions: function foreignCountryOptions(isEntityOwner) {
      if (isEntityOwner) {
        return _address.FOREIGN_ENTITY_OWNER_COUNTRIES_OPTIONS;
      }
      return _address.FOREIGN_COUNTRIES_OPTIONS;
    },


    actions: {
      checkForeignApplicant: function checkForeignApplicant() {
        get(this, 'checkForeignApplicant')();
      }
    }
  }, (_applyDecoratedDescriptor(_obj, 'isMobileReadOnly', [_dec], Object.getOwnPropertyDescriptor(_obj, 'isMobileReadOnly'), _obj), _applyDecoratedDescriptor(_obj, 'foreignCountryOptions', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'foreignCountryOptions'), _obj)), _obj)));
});