define('account-opening/components/trader/beneficial-owners-section', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    f: null,

    entity: null
  });
});