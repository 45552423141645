define('account-opening/services/twapi-toaster', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var MessageTypeArray = Ember.ArrayProxy.extend({
    all: null,
    types: null,

    // TODO: optimize
    content: Ember.computed('all.[]', 'types.[]', function () {
      var all = this.get('all');
      var types = this.get('types');
      return Ember.A(all.filter(function (msg) {
        return types.includes(msg.get('type'));
      }));
    })
  });

  var Message = Ember.Object.extend({
    type: null,
    text: null
  });

  exports.default = Ember.Service.extend({
    timeouts: {
      success: 5000,
      warning: 5000,
      alert: null
    },

    messages: Ember.computed(function () {
      return Ember.A();
    }),

    success: function success(text, timeout) {
      this.post('success', text, timeout);
    },
    warning: function warning(text, timeout) {
      this.post('warning', text, timeout);
    },
    alert: function alert(text, timeout) {
      this.post('alert', text, timeout);
    },
    post: function post(type, text, timeout) {
      var _this = this;

      if (timeout === undefined) {
        timeout = this.get('timeouts.' + type);
      }

      var msg = Message.create({ type: type, text: text });
      this.get('messages').pushObject(msg);

      if (timeout) {
        Ember.run.later(function () {
          return _this.clear(msg);
        }, timeout);
      }
    },
    clear: function clear(msg) {
      this.get('messages').removeObject(msg);
    },
    messagesFor: function messagesFor() {
      for (var _len = arguments.length, types = Array(_len), _key = 0; _key < _len; _key++) {
        types[_key] = arguments[_key];
      }

      var key = types.sort().join(',');
      var cache = this.get('_messagesForCache');

      if (!cache[key]) {
        var all = this.get('messages');
        cache[key] = MessageTypeArray.create({ all: all, types: types });
      }

      return cache[key];
    },


    _messagesForCache: Ember.computed(function () {
      return {};
    })
  });
});