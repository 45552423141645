define('account-opening/controllers/referred-prospects', ['exports', 'account-opening/components/registration-form', 'ember-decorators/object'], function (exports, _registrationForm, _object) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _desc, _value, _obj;

  var Controller = Ember.Controller,
      _Ember$computed = Ember.computed,
      alias = _Ember$computed.alias,
      readOnly = _Ember$computed.readOnly,
      get = Ember.get,
      getProperties = Ember.getProperties,
      service = Ember.inject.service,
      setProperties = Ember.setProperties;
  exports.default = Controller.extend((_dec = (0, _object.computed)('referredProspect.referralCode'), (_obj = {
    accountOpening: service(),

    referral: service(),

    customer: alias('model'),

    referredProspect: readOnly('referral.referredProspect'),

    hasPreregistrationProspect: readOnly('referral.hasPreregistrationProspect'),

    partnerName: function partnerName(referralCode) {
      return (0, _registrationForm.getPartnerName)(referralCode);
    },


    actions: {
      confirm: function confirm() {
        var referredProspect = get(this, 'referredProspect');

        var _getProperties = getProperties(referredProspect, 'email', 'employer', 'firstName', 'lastName', 'mailingCity', 'mailingCountry', 'mailingPostalCode', 'mailingStateRegion', 'mailingStreetOne', 'mailingStreetTwo', 'mobilePhone', 'occupation'),
            email = _getProperties.email,
            employer = _getProperties.employer,
            firstName = _getProperties.firstName,
            lastName = _getProperties.lastName,
            mailingCity = _getProperties.mailingCity,
            mailingCountry = _getProperties.mailingCountry,
            mailingPostalCode = _getProperties.mailingPostalCode,
            mailingStateRegion = _getProperties.mailingStateRegion,
            mailingStreetOne = _getProperties.mailingStreetOne,
            mailingStreetTwo = _getProperties.mailingStreetTwo,
            mobilePhone = _getProperties.mobilePhone,
            occupation = _getProperties.occupation;

        var customer = get(this, 'customer');
        setProperties(customer, {
          email: email,
          firstName: firstName,
          lastName: lastName,
          mobilePhoneNumber: mobilePhone
        });

        var address = get(customer, 'address');
        setProperties(address, {
          city: mailingCity,
          country: mailingCountry,
          postalCode: mailingPostalCode,
          stateRegion: mailingStateRegion,
          streetOne: mailingStreetOne,
          streetTwo: mailingStreetTwo
        });

        var suitability = get(customer, 'customerSuitability');
        setProperties(suitability, {
          employerName: employer,
          occupation: occupation
        });

        this.transitionToRoute('step');
      }
    }
  }, (_applyDecoratedDescriptor(_obj, 'partnerName', [_dec], Object.getOwnPropertyDescriptor(_obj, 'partnerName'), _obj)), _obj)));
});