define('account-opening/amb-form-plugins/i18n', ['exports', 'ember-ambitious-forms/mixins/lookup'], function (exports, _lookup2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.Plugin = undefined;
  exports.autoLoad = autoLoad;
  function autoLoad(appInstance) {
    return appInstance.hasRegistration('service:i18n');
  }

  var Plugin = exports.Plugin = Ember.Mixin.create(_lookup2.default, {
    i18n: Ember.inject.service(),

    _lookupCache: Ember.computed.oneWay('i18n.locale'),

    _lookup: function _lookup(key, args) {
      return this.get('i18n').t(key, args);
    },
    _lookupExists: function _lookupExists(key) {
      return this.get('i18n').exists(key);
    }
  });

  exports.default = { autoLoad: autoLoad, Plugin: Plugin };
});