define('account-opening/validators/entity-tax-number', ['exports', 'twapi/validators/entity-tax-number'], function (exports, _entityTaxNumber) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _entityTaxNumber.default;
    }
  });
});