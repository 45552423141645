define('account-opening/routes/done', ['exports', 'twapi/utils/computed', 'account-opening/utils/mobile-notification'], function (exports, _computed, _mobileNotification) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route,
      get = Ember.get,
      service = Ember.inject.service;
  exports.default = Route.extend({
    twapiCookies: service(),

    config: _computed.default.registry.resolve('config:environment'),

    beforeModel: function beforeModel() {
      if (!get(this, 'twapiCookies').hasCongratulations()) {
        get(this, 'twapiCookies').setCongratulations(true);
      }
    },


    actions: {
      didTransition: function didTransition() {
        (0, _mobileNotification.notifyAccountOpeningApplicationComplete)(get(this, 'session.response.sessionToken'));
      }
    }
  });
});