define('account-opening/routes/step/security', ['exports', 'twapi/mixins/reset-scroll-mixin'], function (exports, _resetScrollMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route,
      get = Ember.get,
      service = Ember.inject.service,
      RSVP = Ember.RSVP;
  exports.default = Route.extend(_resetScrollMixin.default, {

    twapiTwoFactor: service(),

    model: function model() {
      return RSVP.hash({
        userTwoFactorMethod: get(this, 'twapiTwoFactor').fetchTwoFactorMethod()
      });
    }
  });
});