define('account-opening/models/document', ['exports', 'twapi/models/document'], function (exports, _document) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _document.default;
    }
  });
});