define('account-opening/components/trader/citizenship-section', ['exports', 'twapi/utils/validation'], function (exports, _validation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      _Ember$computed = Ember.computed,
      and = _Ember$computed.and,
      not = _Ember$computed.not,
      get = Ember.get,
      service = Ember.inject.service,
      set = Ember.set;
  exports.default = Component.extend({
    accountOpening: service(),

    validateForeignRetirement: and('customer.address.isForeign', 'application.isRetirement'),

    actions: {
      checkForeignApplicant: function checkForeignApplicant() {
        if (!get(this, 'customer.isUsCitizen')) {
          set(this, 'customer.citizenshipCountry', null);
        }
        get(this, 'checkForeignApplicant')();
      }
    }
  }).reopen(_validation.default.build({
    isUsCitizen: {
      presence: true,
      inclusion: { in: [false, true] },
      exclusion: [{
        in: [true],
        disabled: not('model.customer.isExpatriate'),
        message: 'tastytrade cannot accept applications for US Citizens residing in foreign countries at the moment. Please provide a physical US address to proceed.'
      }, {
        in: [false],
        disabled: not('model.validateForeignRetirement'),
        message: 'Foreign accounts are not eligible for retirement accounts'
      }]
    }
  }));
});