define('account-opening/instance-initializers/ember-cp-validations', ['exports', 'twapi/utils/validation'], function (exports, _validation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(appInstance) {
    _validation._setOwner(appInstance);
  } // Workaround for https://github.com/offirgolan/ember-cp-validations#basic-usage---objects

  exports.default = {
    name: 'twapi.ember-cp-validations',
    initialize: initialize
  };
});