define('account-opening/routes/application', ['exports', 'ember-simple-auth/mixins/application-route-mixin'], function (exports, _applicationRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route,
      service = Ember.inject.service;
  exports.default = Route.extend(_applicationRouteMixin.default, {
    session: service(),

    sessionAuthenticated: function sessionAuthenticated() {
      this.transitionTo('index');
    }
  });
});