define('account-opening/services/twapi-address-suggestions', ['exports', 'twapi/services/twapi-address-suggestions'], function (exports, _twapiAddressSuggestions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _twapiAddressSuggestions.default;
    }
  });
});