define('account-opening/models/supporting-document-verification', ['exports', 'twapi/models/supporting-document-verification'], function (exports, _supportingDocumentVerification) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _supportingDocumentVerification.default;
    }
  });
});