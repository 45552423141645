define('account-opening/components/trader/entity-account-activity-section', ['exports', 'twapi/models/account-opening-application', 'twapi/utils/validation'], function (exports, _accountOpeningApplication, _validation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      alias = Ember.computed.alias,
      service = Ember.inject.service;
  exports.default = Component.extend({
    accountOpening: service(),

    accountActivityType: alias('accountOpening.application.accountActivityType'),

    initialDepositType: alias('accountOpening.application.initialDepositType')
  }).reopen(_validation.build({
    accountActivityType: {
      inclusion: { in: _accountOpeningApplication.ACCOUNT_ACTIVITY_TYPES }
    },
    initialDepositType: {
      inclusion: { in: _accountOpeningApplication.ENTITY_INITIAL_DEPOSIT_TYPES }
    }
  }));
});