define('account-opening/locales/en/translations', ['exports', 'lodash', 'twapi/locales/en/translations', 'twapi/utils/constant'], function (exports, _lodash, _translations, _constant) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _lodash.default.merge({}, _translations.default, {
    'amb-form': {
      'account/residency-section': {
        'country': 'What is your country of residence?',
        'country.options': _lodash.default.get(_translations.default, 'amb-form.common')['options.available-countries']
      },
      'estate-percent.total': 'Total',
      'entity': {
        'has-beneficial-owners': 'Are there any individuals, including yourself, who directly or indirectly own 10% or more of the entity?',
        'has-foreign-bank-affiliation': 'Is this account a Foreign Bank organized under foreign law\n                                      and located outside of the United States as defined by Title\n                                      31 of the Code of Federal Regulations',
        'has-foreign-institution-affiliation': 'Is the account maintained for a Foreign Financial Institution\n                                             as defined by Title 31 of the Code of Federal Regulations?'
      },
      'security/authenticator-form': {
        'one-time-password': _lodash.default.get(_translations.default, 'amb-form.common.two-factor.one-time-password')
      },
      'security/sms-form': {
        'one-time-password': _lodash.default.get(_translations.default, 'amb-form.common.two-factor.one-time-password')
      },
      'step/account': {
        'entity-type.options': {
          'c.-corp': 'C Corp',
          'llc': 'LLC',
          'partnership': 'Partnership',
          's.-corp': 'S Corp',
          'trust': 'Trust'
        },
        'joint-type.options': {
          'joint-tenants-with-rights-of-survivorship': 'With Rights of Survivorship (WROS)',
          'joint-tenants-in-common': 'Tenants in Common'
        },
        'ownership-type.options': {
          'individual': 'Individual',
          'entity-trust': 'Entity / Trust'
        },
        'retirement-type.options': {
          'sep': 'SEP IRA'
        }
      },
      'step/review': {
        'has-signature': 'Signature',
        'has-signature.hint': '(Please sign below)'
      },
      'step/security': {
        'two-factor-type': 'Adding two factor authentication to your account offers an additional layer of security when performing sensitive actions.',
        'two-factor-type.options': {
          'authenticator': 'Authenticator App',
          'sms': 'SMS'
        }
      },
      'customer.is-permanent-resident': 'Are you a permanent resident?',
      'account-opening-application.decedent-name': 'Name of Deceased',
      'account-opening-application.letter-of-explanation': 'Explanation',
      'account-opening-application.letter-of-explanation.hint': '(reason that home address is in a foreign country and mailing address is domestic)',

      'account-opening-application.reference-source': 'How did you hear about tastytrade?',
      'account-opening-application.reference-source.options': {
        'tastylive': 'tastylive',
        'internet': 'Internet Search',
        'live-events': 'Live Events',
        'personal-referral': 'Personal Referral'
      },
      'account-opening-application.referred-name': 'Referral Name',
      'account-opening-application.referred-relationship': 'Relationship to Referral',

      'account-opening-application.primary-banking': 'Primary Bank Name',
      'account-opening-application.value-initial-deposit': 'Anticipated Initial Deposit Amount',
      'account-opening-application.initial-deposit-type': 'Initial Deposit Type',
      'account-opening-application.initial-deposit-type.options': {
        'account-transfer': 'Account Transfer',
        'wire': 'Wire'
      },
      'account-opening-application.initial-funding-source': 'Initial Source of Funds',
      'account-opening-application.liquidity-needs': 'Liquidity Needs',
      'account-opening-application.liquidity-needs.options': {
        'very-important': 'Very Important',
        'somewhat-important': 'Somewhat Important',
        'not-important': 'Not Important'
      },
      'account-opening-application.expected-withdrawals': 'Expected Withdrawals',
      'account-opening-application.expected-withdrawals.options': {
        'frequent': 'Frequent',
        'occasional': 'Occasional',
        'rare': 'Rare'
      },
      'account-opening-application.account-activity-type': 'Account Activity Type',
      'account-opening-application.account-activity-type.options': {
        'active-trading': 'Active Trading',
        'long-term-investing': 'Long Term Investing',
        'short-term-investing': 'Short Term Investing'
      },
      'trader/citizenship-section.is-us-citizen': 'Are you a US Citizen?',
      'trader/citizenship-section.is-us-citizen.options': {
        'true': 'Yes',
        'false': 'No'
      },
      'trader/trusted-contact-section.has-trusted-contact': 'Do you want to add a trusted contact?',
      'trader/trusted-contact-section.has-trusted-contact.options': _lodash.default.get(_translations.default, 'amb-form.common')['options.boolean']
    },

    'account-type-display': {
      'c.-corp': 'C Corp {{type}}',
      'individual': 'Individual {{type}}',
      'beneficiary-traditional-ira': 'Beneficiary Traditional IRA',
      'beneficiary-roth-ira': 'Beneficiary Roth IRA',
      'joint-tenants-with-rights-of-survivorship': 'Joint WROS {{type}}',
      'joint-tenants-in-common': 'Joint TIC {{type}}',
      'llc': 'LLC {{type}}',
      'partnership': 'Partnership {{type}}',
      'roth-ira': 'Roth IRA',
      's.-corp': 'S Corp {{type}}',
      'sep': 'SEP IRA',
      'traditional-ira': 'Traditional IRA',
      'trust': 'Trust {{type}}'
    },

    'choose-one': 'Choose one',

    'document-verification.success': 'Your document has been verified',

    'helpful-hints': {
      'trader.entity': 'tastytrade only accepts applications for US entities at this time.',
      'trader.estate-percent': 'TODO',
      'trader.personal': 'As a broker-dealer, tastytrade is required to obtain and verify certain identifying information, such as your date of birth and a government-issued ID number, prior to opening your account.',
      'trader.employment.backupWithholding': "Unless notified by the IRS, it's likely you're not subject to backup withholding.",
      'trader.beneficial-owners': "Industry regulations require us to maintain a record of the entity's beneficial owners.",
      'trader.affiliation': 'As a broker-dealer, tastytrade is required to gather certain information about affiliations you may have, which is why we ask these questions.',
      'trader.original-account-owner': 'The name of the original IRA account owner who named you as their beneficiary.',
      'access.knowledge': 'To help us complete your investor profile, tastytrade needs to find out about your trading knowledge regarding these products.',
      'access.tradingObjectives': 'To help us complete your investor profile, tastytrade needs to find out about your investment objective for this account. We only allow you to select one, so if you trade with multiple objectives in mind, choose the one that is most important to you.',
      'access.financial': 'tastytrade is required to capture certain details about your financial information.',
      'security.validate': 'tastytrade requires 2-step authentication in order to provide enhanced security for your protection. This allows us to match your mobile device with your trading account.',
      'documents.help': 'Download and read each of the following customer documents. By selecting Yes, you are indicating that you understand and agree to all of the provisions in each document.'
    },

    'referral.confirmation': {
      'marketing-partner': 'Welcome to tastytrade! We\'re glad you found us through our friends at <strong>{{referralOwner}}</strong>. Let\'s get your brokerage experience started!',
      'valid-code': 'Welcome to tastytrade! The referral code <strong>{{referralOwner}}</strong> is confirmed! Let\'s get your brokerage experience started!',
      'valid-general': 'Welcome to tastytrade! Your referral code is confirmed! Let\'s get your brokerage experience started!'
    },

    'steps.account': {
      'entity-type-description': {
        'c.-corp': 'C Corps are established legal entities that have been authorized by a US state to conduct business. C Corps\n                pay income taxes at the corporate level. ',
        'llc': 'A Limited Liability Company (LLC) is an unincorporated legal entity that provides limited liability to its owners\n             and a pass-through income tax status.',
        'partnership': 'A Partnership is a legally established entity consisting of two or more individuals. Partnerships do not\n                     pay income taxes; profits and losses are passed through to the individual partners.',
        's.-corp': 'S Corps are established legal entities that have been authorized by a US state to conduct business. S Corps\n                do not pay income taxes at the corporate level and have no more than 100 shareholders.',
        'trust': 'A Revocable Trust (commonly referred to as a \'living trust\') may be canceled or altered at any time and uses\n               the social security number of the grantor as its tax identification number.',
        'trust2': 'An Irrevocable Trust can\'t be modified once the assets have been transferred into the trust, and it requires\n                a separate Federal tax identification number.'
      },
      'entity-type-warning': {
        'c.-corp': 'Be prepared to upload a copy of the C Corp\'s Certificate Of Good Standing or Articles Of Incorporation as\n                well as a signed copy of the Apex Corporate Account Agreement at the time you submit your online application.',
        'llc': 'Be prepared to upload a copy of the LLC\'s Certificate Of Good Standing or the legal LLC Operating Agreement as\n             well as a signed copy of the Apex LLC Agreement at the time you submit your online application.',
        'partnership': 'Be prepared to upload a copy of the legal Partnership Agreement as well as a signed copy of the Apex\n                     Partnership Agreement at the time you submit your online application.',
        's.-corp': 'Be prepared to upload a copy of the S Corp\'s Certificate Of Good Standing or Articles Of Incorporation as\n                well as a signed copy of the Apex Corporate Account Agreement at the time you submit your online application.',
        'trust': 'Be prepared to upload a copy of the legal Trust Certificate as well as a signed copy of the Apex Trust\n               Agreement at the time you submit your online application.'
      },
      'joint-type-description': {
        'joint-tenants-with-rights-of-survivorship': 'A joint account type in which two owners have equals share of assets.\n                                                    When one owner dies, the surviving owner will have full rights to the account.',
        'joint-tenants-in-common': 'A joint account type in which two owners each have a specific proportion of the account\'s assets.\n                                 When one account owner dies, their assets are passed on to their estate.'
      },
      'ownership-type-description': {
        'individual': 'Individual accounts have a single owner.',
        'joint': 'You share access and control of this account with another person.',
        'entity-trust': 'Entity and trust accounts have a primary owner as well as additional owners with trading authorization.\n                      Please note that most Entity / Trust account types are considered "Professional" accounts by the various\n                      exchanges; applicable quote fees may apply. Only US Entity / Trust accounts are offered at this time.'
      },

      'restricted-ownership-type-message': {
        'individual': 'If you are looking to open an Individual account;',
        'joint': 'If you are looking to open a Joint account;',
        'entity-trust': 'If you are looking to open an Entity account;'
      },

      'margin-type-description': {
        'margin': 'Margin is the most flexible kind of account. It allows you to borrow money to trade with and enables you to use all of the trading strategies available at tastytrade.',
        'cash': 'In a cash account trades are fully paid for by you, which limits your access to certain trading strategies. Spreads and uncovered options cannot be traded in a cash account.',
        'retirement': 'Retirement accounts offer different tax benefits, depending on the type. A tastytrade retirement account has limited margin capabilities, which enables you to use defined risk spreads in addition to the basic strategies allowed in a cash account.'
      },

      'retirement-type-description': {
        'beneficiary-roth-ira': 'A Beneficiary Roth IRA (also known as an “Inherited Roth IRA”) is an account that is opened when a designated beneficiary inherits a Roth IRA after the original owner is deceased. Similarly to the original Roth IRA, required minimum distributions are generally tax free.',
        'beneficiary-traditional-ira': 'A Beneficiary Traditional IRA (also known as an "Inherited Traditional IRA") is an account that is opened when a designated beneficiary inherits an IRA after the original owner is deceased. Similarly to the original Traditional IRA, qualifying contributions are generally made with “before tax” money, profits are able to grow “tax-deferred”, and qualifying withdrawals are taxed at your ordinary income rate.',
        'roth-ira': 'A Roth IRA allows profits to grow tax free, but deposits are made with “after tax” money. Contributions can be withdrawn tax and penalty free at any time, and profits can be withdrawn tax and penalty free if they are qualifying distributions.',
        'sep': 'A SEP (Simplified Employee Pension Plan) IRA is a retirement account for self-employed individuals and small business owners/employees. A SEP IRA is a tax-deferred account. This means qualifying contributions are made with “before tax” money, profits are able to grow “tax-deferred”, and qualifying withdrawals are taxed at the account holder’s ordinary tax rate.',
        'traditional-ira': 'A Traditional IRA is a tax-deferred account. This means qualifying contributions are made with “before tax” money, profits are able to grow “tax-deferred”, and qualifying withdrawals are taxed at your ordinary income rate.'
      }
    },
    'steps.trader': {
      'contact-info': {
        'review': 'Please review your contact and personal information for accuracy. <br> Click \'continue\' to proceed with submitting your application.'
      },
      'important-info': {
        'body': 'To help the government fight the funding of terrorism and money laundering activities, federal law requires financial institutions to obtain, verify, and record information that identifies each person who opens an account. Therefore, we will ask you for your name, address, date of birth, and other personal information to identify you. For verification purposes, we may also utilize a third-party information provider and/or service and reserve the right to ask you for a copy of your driver\'s license or other identifying documents.'
      },
      'trusted-contact-info': {
        'body': 'As a broker-dealer we are authorized to contact your trusted contact person and disclose information about your account to address possible financial exploitation, to confirm the specifics of your current contact information, health status, or the identity of any legal guardian, executor, trustee, or holder of a power of attorney, or as otherwise permitted by FINRA Rule 2165.'
      },
      'is-us-resident': {
        'contact-information': 'Do you currently reside in the United States?',
        'beneficial-owners': 'Does the beneficial owner currently reside in the United States?',
        'mailing-address': 'Is your mailing address located in the United States?'
      }
    },

    'step.documents': {
      'c-corp.upload': 'Please upload a signed copy of the Apex Corporate Account Agreement and a copy of the Corporation\'s Articles Of Incorporation or Certificate Of Good Standing.',
      'foreign-document.upload.address': 'As a resident of a country other than the United States, we need additional documentation to help us verify your residential address. Please upload a photocopy of an official document sent to the same physical address provided on your application.',
      'foreign-document.upload.id': 'As a resident of a country other than the United States, we need additional documentation to help us verify your account information. Please upload a photocopy of your passport photo page, drivers license or national ID.',
      'identity-document.description': 'Why do we need this? We were unable to verify your information electronically. Don’t worry, you’re not the first. It could be due to a credit freeze, a typo, or you’re just a youngster.',
      'identity-document.upload': 'Please help us verify your important account information, and use this opportunity to securely upload your document(s) to keep the ball rolling with your application!',
      'identity-document.id-document': 'Upload a photo or photocopy of a valid Driver’s License, Passport, or other government&#8209;issued photo ID.',
      'identity-document.ssn-document': 'Upload a photo or photocopy of a valid SSN Card or W-2 issued within the last 12 months.',
      'identity-document.address-document': 'Upload a photo or photocopy of a cable bill, utility bill, or bank statement addressed to you and issued within the last 12 months.',
      'identity-document.visa-document': 'Upload a photo or photocopy of a valid, unexpired U.S. Visa',
      'llc.upload': 'Please upload a signed copy of the Apex Limited Liability Company Agreement and a copy of the LLC\'s Certificate Of Good Standing or legal Operating Agreement.',
      'partnership.upload': 'Please upload a signed copy of the Apex Partnership Agreement and a copy of the legal Partnership Agreement.',
      's-corp.upload': 'Please upload a signed copy of the Apex Corporate Account Agreement and a copy of the Corporation\'s Articles Of Incorporation or Certificate Of Good Standing.',
      'skip-upload-modal': {
        'all': 'You will be able to submit the application now, but it will be incomplete and will not be reviewed until you have uploaded the documents in your profile.',
        'id': 'You will be able to submit the application now, but it will be incomplete and will not be reviewed until you have uploaded the ID document in your profile.',
        'address': 'You will be able to submit the application now, but it will be incomplete and will not be reviewed until you have uploaded the address document in your profile.'
      },
      'supported.upload': 'JPG, PNG and GIF file formats are supported. File size should be less than ' + _constant.MAX_FILE_SIZE + '.',
      'trust.upload': 'Please upload a signed copy of the Apex Trust Agreement and a copy of the legal Trust Certificate.'
    }
  });
});