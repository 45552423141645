define('account-opening/models/supporting-document-verification-document', ['exports', 'twapi/models/supporting-document-verification-document'], function (exports, _supportingDocumentVerificationDocument) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _supportingDocumentVerificationDocument.default;
    }
  });
});