define('account-opening/models/user-authenticator-confirmation', ['exports', 'twapi/models/user-authenticator-confirmation'], function (exports, _userAuthenticatorConfirmation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _userAuthenticatorConfirmation.default;
    }
  });
});