define('account-opening/components/amb-form-dateselect-identity', ['exports', 'ember-decorators/object', 'lodash', 'account-opening/components/amb-form-dateselect'], function (exports, _object, _lodash, _ambFormDateselect) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _desc, _value, _obj;

  exports.default = _ambFormDateselect.default.extend((_obj = {
    yearOptions: function yearOptions() {
      var latestYear = new Date().getFullYear();
      return (0, _lodash.default)(latestYear).range(latestYear - _ambFormDateselect.MAX_AGE, -1).map(String).value();
    }
  }, (_applyDecoratedDescriptor(_obj, 'yearOptions', [_object.computed], Object.getOwnPropertyDescriptor(_obj, 'yearOptions'), _obj)), _obj));
});