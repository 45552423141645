define('account-opening/models/user-two-factor-status', ['exports', 'twapi/models/user-two-factor-status'], function (exports, _userTwoFactorStatus) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _userTwoFactorStatus.default;
    }
  });
});