define('account-opening/components/trader/personal-body', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      readOnly = Ember.computed.readOnly,
      service = Ember.inject.service;
  exports.default = Component.extend({

    accountOpening: service(),

    hasPendingOrApprovedApplication: readOnly('customer.hasPendingOrApprovedApplication')

  });
});