define('account-opening/services/joint-account-continuation', ['exports', 'ember-decorators/object', 'twapi/utils/computed', 'twapi/models/applicant', 'twapi/models/applicant-data'], function (exports, _object, _computed, _applicant, _applicantData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _desc, _value, _obj;

  var Service = Ember.Service,
      _Ember$computed = Ember.computed,
      alias = _Ember$computed.alias,
      bool = _Ember$computed.bool,
      readOnly = _Ember$computed.readOnly,
      service = Ember.inject.service,
      isNone = Ember.isNone;
  exports.default = Service.extend((_dec = (0, _object.computed)('applicants.[]', 'accountOpening.customer', 'session.response.user.email', 'accountOpening.customer.{' + _applicantData.CUSTOMER_APPLICANT_PROPERTIES.join(',') + '}'), _dec2 = (0, _object.computed)('currentApplicant', 'applicants.[]'), (_obj = {
    accountOpening: service(),

    session: service(),

    accountManagementUrl: alias('accountOpening.accountManagementUrl'),

    applicants: readOnly('accountOpening.application.applicants'),

    isCurrentApplicantComplete: bool('currentApplicant.isComplete'),

    primaryApplicant: alias('otherApplicant'),

    config: _computed.default.registry.resolve('config:environment'),

    currentApplicant: function currentApplicant(applicants, customer, email) {
      var applicant = (0, _applicant.findApplicant)(applicants, email);

      if (isNone(applicant)) {
        return;
      }

      return (0, _applicantData.fromCustomer)(applicant, customer);
    },
    otherApplicant: function otherApplicant(currentApplicant, applicants) {
      return (0, _applicant.otherApplicant)(applicants, currentApplicant);
    }
  }, (_applyDecoratedDescriptor(_obj, 'currentApplicant', [_dec], Object.getOwnPropertyDescriptor(_obj, 'currentApplicant'), _obj), _applyDecoratedDescriptor(_obj, 'otherApplicant', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'otherApplicant'), _obj)), _obj)));
});