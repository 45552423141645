define('account-opening/components/trader/define-beneficial-owners-body', ['exports', 'twapi/models/beneficial-owner', 'ember-decorators/object', 'twapi/utils/restless', 'twapi/utils/validation', 'twapi/models/address'], function (exports, _beneficialOwner, _object, _restless, _validation, _address) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _desc, _value, _obj;

  var Component = Ember.Component,
      _Ember$computed = Ember.computed,
      bool = _Ember$computed.bool,
      gt = _Ember$computed.gt,
      readOnly = _Ember$computed.readOnly,
      get = Ember.get,
      isEmpty = Ember.isEmpty,
      set = Ember.set;
  exports.default = Component.extend((_dec = (0, _object.computed)('hasEditableOwner', 'entity.hasBeneficialOwners'), _dec2 = (0, _object.computed)('entity.entityBeneficialOwners.@each.ownershipPercent'), (_obj = {

    currentOwner: null,

    entity: null,

    hasEditableOwner: bool('currentOwner'),

    hasOwners: bool('entity.entityBeneficialOwners.length'),

    hasMultipleOwners: gt('entity.entityBeneficialOwners.length', 1),

    address: readOnly('currentOwner.address'),

    isAddressDomestic: readOnly('address.isDomestic'),

    foreignCountryOptions: _address.FOREIGN_ENTITY_OWNER_COUNTRIES_OPTIONS,

    isComplete: function isComplete(hasEditableOwner, hasBeneficialOwners) {
      return !hasEditableOwner || !hasBeneficialOwners;
    },
    total: function total(beneficialOwners) {
      if (isEmpty(beneficialOwners)) {
        return 0;
      }

      return beneficialOwners.reduce(function (sum, owner) {
        return sum + Number(get(owner, 'ownershipPercent'));
      }, 0);
    },
    init: function init() {
      this._super.apply(this, arguments);

      if (isEmpty(get(this, 'entity.entityBeneficialOwners'))) {
        set(this, 'currentOwner', _beneficialOwner.default.create());
      }
    },


    actions: {
      cancelOwnerInput: function cancelOwnerInput() {
        _restless.default.restoreFields(get(this, 'currentOwner'));

        set(this, 'currentOwner', null);
      },
      editOwner: function editOwner(owner) {
        _restless.default.saveFields(owner);

        set(this, 'currentOwner', owner);
      },
      newOwner: function newOwner() {
        set(this, 'currentOwner', _beneficialOwner.default.create());
      },
      removeOwner: function removeOwner(owner) {
        get(this, 'entity.entityBeneficialOwners').removeObject(owner);
      },
      saveOwner: function saveOwner() {
        var owner = get(this, 'currentOwner');
        var owners = get(this, 'entity.entityBeneficialOwners');

        if (!owners.contains(owner)) {
          owners.pushObject(owner);
        }

        set(this, 'currentOwner', null);
      }
    }
  }, (_applyDecoratedDescriptor(_obj, 'isComplete', [_dec], Object.getOwnPropertyDescriptor(_obj, 'isComplete'), _obj), _applyDecoratedDescriptor(_obj, 'total', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'total'), _obj)), _obj))).reopen(_validation.build({
    total: { number: { lte: 100, message: 'must not exceed 100%' } },
    isComplete: { inclusion: { in: [true] } }
  }));
});