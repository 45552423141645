define('account-opening/instance-initializers/default-error-handling', ['exports', 'account-opening/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.createLogger = createLogger;
  exports.toasterAlert = toasterAlert;
  exports.initialize = initialize;
  function createLogger(environment, appInstance) {
    if (environment === 'development') {
      var toaster = appInstance.lookup('service:twapi-toaster');
      return toasterAlert.bind(toaster);
    }

    // TODO: staging / production error logging
    return Ember.K;
  }

  function toasterAlert(error) {
    var msg = error && error.stack || error + ' - <missing stacktrace>';
    this.alert(msg);
  }

  function initialize(appInstance) {
    var log = createLogger(_environment.default.environment, appInstance);

    Ember.RSVP.on('error', function (error) {
      var msg = error && error.stack || JSON.stringify(error);
      log('RSVP rejected: ' + msg);
    });
    window.onerror = function (message, _url, _lineNumber, _lineColumn, error) {
      log(error || message);
    };
  }

  exports.default = {
    name: 'twapi.default-error-handling',
    initialize: initialize
  };
});