define('account-opening/components/amb-form-signature', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      alias = Ember.computed.alias,
      set = Ember.set;
  exports.default = Component.extend({
    classNames: ['amb-form-signature'],

    signature: alias('value'),

    actions: {
      clear: function clear() {
        set(this, 'signature', null);
      },
      signed: function signed(signature) {
        set(this, 'signature', signature);
      }
    }
  });
});