define('account-opening/instance-initializers/twapi.restless', ['exports', 'ember-restless', 'twapi/serializers/twapi', 'twapi/adapters/twapi'], function (exports, _emberRestless, _twapi, _twapi2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(appInstance) {
    // Force these to be singletons...
    appInstance.register('serializer:twapi', _twapi.default, { singleton: true });
    appInstance.register('adapter:twapi', _twapi2.default, { singleton: true });

    var client = _emberRestless.default.Client.create({
      adapter: appInstance.lookup('adapter:twapi')
    });

    // RESTLess uses this 'global'...
    appInstance.set('application.Client', client);
  }

  exports.default = {
    name: 'twapi.restless',
    initialize: initialize
  };
});