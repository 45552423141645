define('account-opening/components/review/beneficiaries-body', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      readOnly = Ember.computed.readOnly;
  exports.default = Component.extend({
    application: null,

    primaryBeneficiaries: readOnly('application.primaryBeneficiaries'),

    contingentBeneficiaries: readOnly('application.contingentBeneficiaries')
  });
});