define('account-opening/components/confirm-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      get = Ember.get,
      set = Ember.set;
  exports.default = Component.extend({
    enabled: false,
    attributeBindings: ['testId:data-test-id'],
    testId: null,

    actions: {
      confirmAction: function confirmAction() {
        set(this, 'enabled', false);
        get(this, 'confirmAction')();
      },
      toggleModal: function toggleModal() {
        set(this, 'enabled', false);
      }
    }
  });
});