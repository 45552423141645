define('account-opening/components/trader/joint-secondary-owner-section', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      alias = Ember.computed.alias,
      get = Ember.get,
      service = Ember.inject.service;
  exports.default = Component.extend({
    jointAccount: service(),

    session: service(),

    email: alias('secondaryApplicant.applicantData.email'),

    secondaryApplicant: alias('jointAccount.secondaryApplicant'),

    onPaste: function onPaste(e) {
      e.preventDefault();
    }
  }).reopen((0, _emberCpValidations.buildValidations)({
    email: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', { type: 'email' }), (0, _emberCpValidations.validator)(function (value, options, model, attribute) {
      if (value.toLowerCase() !== get(model, 'session.response.user.email').toLowerCase()) {
        return true;
      }

      return this.createErrorMessage('duplicateEmail', value, options);
    }, {
      message: 'cannot match your own email address'
    })]
  }));
});