define('account-opening/components/fully-paid-securities-lending/twapi-acknowledgement', ['exports', 'twapi/components/fully-paid-securities-lending/twapi-acknowledgement'], function (exports, _twapiAcknowledgement) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _twapiAcknowledgement.default;
    }
  });
});