define('account-opening/routes/step/trader', ['exports', 'twapi/mixins/reset-scroll-mixin', 'account-opening/services/step'], function (exports, _resetScrollMixin, _step) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      service = Ember.inject.service,
      Route = Ember.Route,
      set = Ember.set,
      RSVP = Ember.RSVP;
  exports.default = Route.extend(_resetScrollMixin.default, {
    accountOpening: service(),

    equifax: service(),

    step: service(),

    twapiTwoFactor: service(),

    model: function model() {
      return RSVP.hash({
        userTwoFactorMethod: get(this, 'twapiTwoFactor').fetchTwoFactorMethod(),
        customer: get(this, 'accountOpening.customerProxy.promise')
      });
    },
    resetController: function resetController(controller, isExiting, transition) {
      if (isExiting) {
        set(controller, 'editBeneficiaries', false);
        var traderStep = get(this, 'step').stepFor(_step.TRADER_STEP);

        if (get(traderStep, 'isComplete')) {
          get(this, 'equifax').setHasReviewed();

          get(this, 'accountOpening').saveAndVerifyCustomer().then(this._saveTrustedContact.bind(this));
        }
      }
    },
    _saveTrustedContact: function _saveTrustedContact() {
      get(this, 'accountOpening').saveTrustedContact();
    }
  });
});