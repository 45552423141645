define('account-opening/amb-form-plugins/restless', ['exports', 'ember-computed-indirect/utils/indirect'], function (exports, _indirect) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.Plugin = undefined;
  exports.autoLoad = autoLoad;
  function autoLoad(appInstance) {
    return typeof RESTless != 'undefined' && RESTless instanceof Ember.Namespace;
  }

  var Plugin = exports.Plugin = Ember.Mixin.create({
    model: Ember.computed.alias('scope'),

    _modelMeta: Ember.computed('model', function () {
      return this.get('model.constructor');
    }),

    scopeName: Ember.computed('_modelMeta.resourceName', function () {
      return this.get('_modelMeta.resourceName') || this._super();
    }),

    fieldType: (0, _indirect.default)('_fieldTypeKey'),
    _fieldTypeKey: Ember.computed('_modelMeta.fields', 'fieldKey', function () {
      if (this.get('_modelMeta.fields')) {
        return '_modelMeta.fields.' + this.get('fieldKey') + '.type';
      } else {
        return '_fieldType';
      }
    }),

    _didUpdate: Ember.observer('value', function () {
      var model = this.get('model');
      if (model && model.trigger) {
        model.trigger('didUpdate');
      }
    })
  });

  exports.default = { autoLoad: autoLoad, Plugin: Plugin };
});