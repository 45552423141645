define('account-opening/components/amb-form-nyes', ['exports', 'ember-ambitious-forms/mixins/converted-options'], function (exports, _convertedOptions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_convertedOptions.default, {
    tagName: 'span',
    classNames: ['amb-form-nyes']
  });
});