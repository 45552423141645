define('account-opening/controllers/application', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller,
      alias = Ember.computed.alias,
      service = Ember.inject.service,
      set = Ember.set;
  exports.default = Controller.extend({
    accountOpening: service(),

    showConfirmLeave: false,

    showConfirmationPrompt: alias('accountOpening.showConfirmationPrompt'),

    actions: {
      openConfirmLeaveModal: function openConfirmLeaveModal() {
        set(this, 'showConfirmLeave', true);
      }
    }
  });
});