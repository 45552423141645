define('account-opening/helpers/has-one', ['exports', 'twapi/helpers/has-one'], function (exports, _hasOne) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _hasOne.default;
    }
  });
  Object.defineProperty(exports, 'hasOne', {
    enumerable: true,
    get: function () {
      return _hasOne.hasOne;
    }
  });
});