define('account-opening/components/trader/business-trader-form', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      readOnly = Ember.computed.readOnly;
  exports.default = Component.extend({
    hasPendingOrApprovedApplication: readOnly('customer.hasPendingOrApprovedApplication')
  });
});