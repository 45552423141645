define('account-opening/services/twapi-cookies', ['exports', 'twapi/services/twapi-cookies'], function (exports, _twapiCookies) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _twapiCookies.default;
    }
  });
});