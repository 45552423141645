define('account-opening/components/top-header', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      get = Ember.get;
  exports.default = Component.extend({
    helpUrl: 'https://support.tastytrade.com/support/s/solutions/folders/43000342950',
    actions: {
      triggerHome: function triggerHome() {
        get(this, 'openModal')();
      }
    }
  });
});