define('account-opening/initializers/session-inject', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {
    application.inject('route', 'session', 'service:session');
    application.inject('controller', 'session', 'service:session');
  }

  exports.default = {
    name: 'twapi.session-inject',
    initialize: initialize
  };
});