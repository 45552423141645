define('account-opening/components/amb-form-domestic-phone-number', ['exports', 'twapi/components/amb-form-domestic-phone-number'], function (exports, _ambFormDomesticPhoneNumber) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _ambFormDomesticPhoneNumber.default;
    }
  });
});