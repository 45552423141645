define('account-opening/models/margin-type', ['exports', 'twapi/models/margin-type'], function (exports, _marginType) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _marginType.default;
    }
  });
});