define('account-opening/components/twapi-currency-mask', ['exports', 'account-opening/templates/components/twapi-currency-mask', 'twapi/utils/sanitize'], function (exports, _twapiCurrencyMask, _sanitize) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      get = Ember.get,
      set = Ember.set;
  exports.default = Component.extend({
    layout: _twapiCurrencyMask.default,

    value: null,

    maskedValue: null,

    allowDecimal: false,

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.updateMaskedValue();
    },
    updateMaskedValue: function updateMaskedValue() {
      set(this, 'maskedValue', get(this, 'value'));
    },


    actions: {
      onUpdate: function onUpdate() {
        var maskedBalance = get(this, 'maskedValue');

        set(this, 'value', _sanitize.currency(maskedBalance));
      }
    }
  });
});