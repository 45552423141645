define('account-opening/components/access-plan-review-overlay', ['exports', 'ember-decorators/object', 'twapi/models/options-level'], function (exports, _object, _optionsLevel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _dec3, _dec4, _desc, _value, _obj;

  var get = Ember.get;
  exports.default = Ember.Component.extend((_dec = (0, _object.computed)('planAccess.eligiblePlansForMarginType'), _dec2 = (0, _object.computed)('currentPlan'), _dec3 = (0, _object.computed)('planAccess.allPlans', 'planAccess.allowedPlans'), _dec4 = (0, _object.computed)('planAccess.allPlans', 'planAccess.allowedPlans'), (_obj = {
    planAccess: Ember.inject.service(),

    classNames: ['access-plan-review-overlay'],

    currentPlan: Ember.computed.alias('model.application.accessPlan'),
    recommendedPlan: Ember.computed.alias('planAccess.recommendedPlan'),
    permissionGroups: Ember.computed.alias('planAccess.permissionGroups'),

    isExpanded: false,
    restricted: false,

    noRestrictionsAvailable: function noRestrictionsAvailable(plans) {
      return plans.some(function (p) {
        return get(p, 'optionsLevel') === _optionsLevel.OPTIONS_LEVELS.NO_RESTRICTIONS;
      });
    },
    currentPlanName: function currentPlanName(currentPlan) {
      return this.get('planAccess').planName(currentPlan);
    },
    ineligiblePlans: function ineligiblePlans(allPlans, allowedPlans) {
      var plans = allPlans.filter(function (plan) {
        return !allowedPlans.some(function (p) {
          return p.name === plan.name;
        });
      });
      return plans;
    },
    accessPlansOptions: function accessPlansOptions(allPlans, allowedPlans) {
      return allPlans.map(function (plan) {
        return Ember.Object.create({
          name: plan.get('name'),
          value: plan.get('name'),
          disabled: !allowedPlans.some(function (p) {
            return p.name === plan.name;
          })
        });
      });
    },


    actions: {
      toggle: function toggle() {
        var _this = this;

        this.toggleProperty('isExpanded');
        // When close, notify potential plan change
        if (!this.get('isExpanded')) {
          var selectedPlan = this.get('planAccess.allowedPlans').find(function (plan) {
            return plan.get('name') === _this.get('currentPlanName');
          });
          // default to current plan if no plan is selected
          var plan = selectedPlan || this.get('currentPlan');
          this.sendAction('onClose', plan);
        }
      }
    }
  }, (_applyDecoratedDescriptor(_obj, 'noRestrictionsAvailable', [_dec], Object.getOwnPropertyDescriptor(_obj, 'noRestrictionsAvailable'), _obj), _applyDecoratedDescriptor(_obj, 'currentPlanName', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'currentPlanName'), _obj), _applyDecoratedDescriptor(_obj, 'ineligiblePlans', [_dec3], Object.getOwnPropertyDescriptor(_obj, 'ineligiblePlans'), _obj), _applyDecoratedDescriptor(_obj, 'accessPlansOptions', [_dec4], Object.getOwnPropertyDescriptor(_obj, 'accessPlansOptions'), _obj)), _obj)));
});