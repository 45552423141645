define('account-opening/routes/document-verification', ['exports', 'twapi/utils/search-params', 'twapi/models/document-verification-message', 'twapi/models/document-verification-payload'], function (exports, _searchParams, _documentVerificationMessage, _documentVerificationPayload) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route,
      isPresent = Ember.isPresent;
  exports.default = Route.extend({
    beforeModel: function beforeModel() {
      var queryParams = _searchParams.getSearchParams(window);

      if (isPresent(queryParams) && queryParams.has('idScanStatus') && queryParams.has('jumioIdScanReference')) {
        var documentVerificationMessage = _documentVerificationMessage.default.create({
          payload: _documentVerificationPayload.default.create({
            value: queryParams.get('idScanStatus').toLowerCase()
          }),
          transactionReference: queryParams.get('jumioIdScanReference')
        });

        var message = JSON.stringify(documentVerificationMessage.serialize());

        // NOTE: [HR] Communicate with the parent window from the IFrame
        window.top.postMessage(message, '*');
      }
    }
  });
});