define('account-opening/models/autotrade-operator', ['exports', 'twapi/models/autotrade-operator'], function (exports, _autotradeOperator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _autotradeOperator.default;
    }
  });
});