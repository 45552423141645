define('account-opening/models/pdt-reset-request-eligibility', ['exports', 'twapi/models/pdt-reset-request-eligibility'], function (exports, _pdtResetRequestEligibility) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _pdtResetRequestEligibility.default;
    }
  });
});