define('account-opening/components/account/ownership-section', ['exports', 'ember-decorators/object', 'account-opening/controllers/step/account', 'twapi/models/account-type', 'twapi/utils/permitted-account-type'], function (exports, _object, _account, _accountType, _permittedAccountType) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _dec3, _dec4, _dec5, _desc, _value, _obj;

  var Component = Ember.Component,
      equal = Ember.computed.equal;
  exports.default = Component.extend((_dec = (0, _object.computed)('isIndividual', 'isJoint', 'isOnlyEntityPermitted', 'isEntityRestricted'), _dec2 = (0, _object.computed)('residencyPermittedAccountTypes.[]'), _dec3 = (0, _object.computed)('residencyPermittedAccountTypes.[]'), _dec4 = (0, _object.computed)('residencyPermittedAccountTypes.[]'), _dec5 = (0, _object.computed)('isIndividualPermitted', 'isEntityTrustPermitted', 'isJointPermitted'), (_obj = {
    isOnlyEntityPermitted: null,

    isEntityRestricted: null,

    ownershipType: null,

    isIndividual: equal('ownershipType', _account.OWNERSHIP_TYPES.INDIVIDUAL),

    isJoint: equal('ownershipType', _account.OWNERSHIP_TYPES.JOINT),

    showDescription: function showDescription(isIndividual, isJoint, isOnlyEntityPermitted, isEntityRestricted) {
      if (isIndividual || isJoint) {
        return !isOnlyEntityPermitted;
      }

      return !isEntityRestricted;
    },
    isIndividualPermitted: function isIndividualPermitted(permittedAccountTypes) {
      return _permittedAccountType.default.isPermitted(permittedAccountTypes, _accountType.ACCOUNT_TYPES.INDIVIDUAL);
    },
    isEntityTrustPermitted: function isEntityTrustPermitted(permittedAccountTypes) {
      return Object.values(_accountType.ENTITY_TYPES).some(function (entityType) {
        return _permittedAccountType.default.isPermitted(permittedAccountTypes, entityType);
      });
    },
    isJointPermitted: function isJointPermitted(permittedAccountTypes) {
      return Object.values(_accountType.JOINT_TYPES).some(function (jointType) {
        return _permittedAccountType.default.isPermitted(permittedAccountTypes, jointType);
      });
    },
    optionValues: function optionValues(isIndividualPermitted, isEntityTrustPermitted, isJointPermitted) {
      var optionValues = [];

      if (isIndividualPermitted) {
        optionValues.push(_account.OWNERSHIP_TYPES.INDIVIDUAL);
      }
      if (isEntityTrustPermitted) {
        optionValues.push(_account.OWNERSHIP_TYPES.ENTITY_TRUST);
      }
      if (isJointPermitted) {
        optionValues.push(_account.OWNERSHIP_TYPES.JOINT);
      }

      return optionValues;
    }
  }, (_applyDecoratedDescriptor(_obj, 'showDescription', [_dec], Object.getOwnPropertyDescriptor(_obj, 'showDescription'), _obj), _applyDecoratedDescriptor(_obj, 'isIndividualPermitted', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'isIndividualPermitted'), _obj), _applyDecoratedDescriptor(_obj, 'isEntityTrustPermitted', [_dec3], Object.getOwnPropertyDescriptor(_obj, 'isEntityTrustPermitted'), _obj), _applyDecoratedDescriptor(_obj, 'isJointPermitted', [_dec4], Object.getOwnPropertyDescriptor(_obj, 'isJointPermitted'), _obj), _applyDecoratedDescriptor(_obj, 'optionValues', [_dec5], Object.getOwnPropertyDescriptor(_obj, 'optionValues'), _obj)), _obj)));
});