define('account-opening/components/access-selection', ['exports', 'lodash', 'ember-decorators/object', 'ember-decorators/object/evented', 'ember-computed-indirect/utils/indirect', 'twapi/utils/ember-run'], function (exports, _lodash, _object, _evented, _indirect, _emberRun) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.Field = undefined;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _desc, _value, _obj, _dec10, _dec11, _desc2, _value2, _obj2;

  var get = Ember.get,
      isEqual = Ember.isEqual;
  var Field = exports.Field = Ember.Object.extend((_dec = (0, _object.computed)('scope'), _dec2 = (0, _object.computed)('fieldKey', 'warningFieldKey'), _dec3 = (0, _object.computed)('fieldKey', 'parent.selected'), _dec4 = (0, _object.computed)('scopeName', 'fieldKey'), _dec5 = (0, _object.computed)('value'), _dec6 = (0, _object.computed)('fieldKey'), _dec7 = (0, _object.computed)('value'), _dec8 = (0, _object.computed)('scopeName', 'fieldKey', 'value'), _dec9 = (0, _object.computed)('hasWarning', 'hasError', 'isSelected', 'hasValue'), (_obj = {
    parent: null,
    fieldKey: null,
    warningFieldKey: null,
    hasError: false,
    errorMessage: null,

    scope: Ember.computed.alias('parent.scope'),

    scopeName: function scopeName(scope) {
      return get(scope, 'constructor.resourceName');
    },
    hasWarning: function hasWarning(fieldKey, warningFieldKey) {
      return isEqual(fieldKey, warningFieldKey);
    },
    isSelected: function isSelected(fieldKey, selectedField) {
      return isEqual(fieldKey, selectedField);
    },
    label: function label(scopeName, fieldKey) {
      return ('amb-form.' + scopeName + '.' + fieldKey).dasherize();
    },


    value: (0, _indirect.default)('_valueKey'),

    displayValue: function displayValue(value) {
      if (typeof value === 'string') return value.toLowerCase();
      return value;
    },
    _valueKey: function _valueKey(fieldKey) {
      return 'scope.' + fieldKey;
    },
    hasValue: function hasValue(value) {
      return !Ember.isBlank(value);
    },
    displayKey: function displayKey(scopeName, fieldKey, value) {
      return ('amb-form.' + scopeName + '.' + fieldKey + '.options.' + value).dasherize();
    },
    iconClass: function iconClass(hasWarning, hasError, isSelected, hasValue) {
      if (hasWarning || hasError) {
        return 'icon-exclamation-circle';
      } else if (isSelected) {
        return 'icon-chevron-right';
      } else if (hasValue) {
        return 'icon-check';
      }
    }
  }, (_applyDecoratedDescriptor(_obj, 'scopeName', [_dec], Object.getOwnPropertyDescriptor(_obj, 'scopeName'), _obj), _applyDecoratedDescriptor(_obj, 'hasWarning', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'hasWarning'), _obj), _applyDecoratedDescriptor(_obj, 'isSelected', [_dec3], Object.getOwnPropertyDescriptor(_obj, 'isSelected'), _obj), _applyDecoratedDescriptor(_obj, 'label', [_dec4], Object.getOwnPropertyDescriptor(_obj, 'label'), _obj), _applyDecoratedDescriptor(_obj, 'displayValue', [_dec5], Object.getOwnPropertyDescriptor(_obj, 'displayValue'), _obj), _applyDecoratedDescriptor(_obj, '_valueKey', [_dec6], Object.getOwnPropertyDescriptor(_obj, '_valueKey'), _obj), _applyDecoratedDescriptor(_obj, 'hasValue', [_dec7], Object.getOwnPropertyDescriptor(_obj, 'hasValue'), _obj), _applyDecoratedDescriptor(_obj, 'displayKey', [_dec8], Object.getOwnPropertyDescriptor(_obj, 'displayKey'), _obj), _applyDecoratedDescriptor(_obj, 'iconClass', [_dec9], Object.getOwnPropertyDescriptor(_obj, 'iconClass'), _obj)), _obj)));

  exports.default = Ember.Component.extend((_dec10 = (0, _evented.on)('init'), _dec11 = _emberRun.scheduleOnce('afterRender'), (_obj2 = {

    classNames: 'access-selection',
    classNameBindings: ['selected:access-selection-has-selected:access-selection-lacks-selected'],

    scope: null,
    fields: null,
    fieldNames: null,
    inputType: 'select',

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var fieldNames = this.get('fieldNames');
      this.set('fields', _lodash.default.map(fieldNames, function (fieldKey) {
        return Field.create({
          fieldKey: fieldKey,
          parent: _this
        });
      }));
    },
    _selectDefault: function _selectDefault() {
      var defaultSelected = this.get('fields').find(function (field) {
        return !field.get('hasValue');
      });
      if (defaultSelected) this.set('selected', defaultSelected.get('fieldKey'));
    },
    validateFields: function validateFields(fields) {
      var _this2 = this;

      this.get('fields').forEach(function (field) {
        _this2.validateField(field);
      });
    },
    validateField: function validateField(field) {
      // check if valid
      var isValid = this.scope.get('validations.attrs.' + field.fieldKey + '.isValid') || !field.get('value');
      if (!isValid) {
        field.set('hasError', true);
        var errors = this.scope.get('validations.attrs.' + field.fieldKey + '.errors');
        var message = errors.map(function (error) {
          return error.message;
        }).join(', ');
        field.set('errorMessage', message);
      } else {
        field.set('hasError', false);
        field.set('errorMessage', null);
      }
    },


    actions: {
      select: function select(targetField) {
        this.set('selected', targetField.get('fieldKey'));
        this.validateFields();
      },
      inputValueSelected: function inputValueSelected(formField) {
        var key = formField.get('fieldKey');
        var fields = this.get('fields');
        var field = fields.findBy('fieldKey', key);
        this.sendAction('onChange', field, fields, this);
      }
    }
  }, (_applyDecoratedDescriptor(_obj2, '_selectDefault', [_dec10, _dec11], Object.getOwnPropertyDescriptor(_obj2, '_selectDefault'), _obj2)), _obj2))).reopenClass({
    positionalParams: 'fieldNames'
  });
});