define('account-opening/controllers/step/account-error', ['exports', 'twapi/utils/computed'], function (exports, _computed) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller,
      alias = Ember.computed.alias,
      service = Ember.inject.service;
  exports.default = Controller.extend({
    accountOpening: service(),

    accountManagementUrl: alias('accountOpening.accountManagementUrl'),

    config: _computed.default.registry.resolve('config:environment')
  });
});