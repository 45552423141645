define('account-opening/components/twapi-two-factor-key', ['exports', 'twapi/components/twapi-two-factor-key'], function (exports, _twapiTwoFactorKey) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _twapiTwoFactorKey.default;
    }
  });
});