define('account-opening/components/documents/twapi-entity-agreement-link', ['exports', 'twapi/components/documents/twapi-entity-agreement-link'], function (exports, _twapiEntityAgreementLink) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _twapiEntityAgreementLink.default;
    }
  });
});