define('account-opening/components/form-section-title', ['exports', 'ember-decorators/object'], function (exports, _object) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _dec3, _desc, _value, _obj;

  exports.default = Ember.Component.extend((_dec = (0, _object.computed)('errorStateValue'), _dec2 = (0, _object.computed)('popupKey'), _dec3 = (0, _object.computed)('errorStateValue', 'hasPopup'), (_obj = {
    i18n: Ember.inject.service(),

    classNames: 'form-section-title',
    classNameBindings: ['classByErrorState', 'hasPopup:has-popup'],

    errorState: null,
    popupKey: null,
    showPopup: false,
    hasPopup: Ember.computed.bool('popupKey'),

    classByErrorState: function classByErrorState(stateValue) {
      return 'form-section-title-error-' + stateValue;
    },
    popupContent: function popupContent(popupKey) {
      if (Ember.isEmpty(popupKey)) {
        return '';
      }

      return this.get('i18n').t('helpful-hints.' + popupKey);
    },
    iconClass: function iconClass(errorValue, hasPopup) {
      switch (errorValue) {
        case 'none':
          return 'tw-icon-state-successful';
        case 'visible':
          return 'tw-icon-state-error';
        default:
          if (hasPopup) {
            return 'tw-icon-state-question';
          } else {
            return 'tw-icon-radio-unselected';
          }
      }
    },


    actions: {
      togglePopup: function togglePopup() {
        if (this.get('hasPopup')) {
          this.toggleProperty('showPopup');
        }
      }
    }
  }, (_applyDecoratedDescriptor(_obj, 'classByErrorState', [_dec], Object.getOwnPropertyDescriptor(_obj, 'classByErrorState'), _obj), _applyDecoratedDescriptor(_obj, 'popupContent', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'popupContent'), _obj), _applyDecoratedDescriptor(_obj, 'iconClass', [_dec3], Object.getOwnPropertyDescriptor(_obj, 'iconClass'), _obj)), _obj))).reopenClass({
    positionalParams: ['text']
  });
});