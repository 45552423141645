define('account-opening/components/documents/identity-document-upload', ['exports', 'ember-decorators/object', 'twapi/utils/slug', 'twapi/utils/validation', 'twapi/utils/constant'], function (exports, _object, _slug, _validation, _constant) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _dec3, _dec4, _desc, _value, _obj;

  var Component = Ember.Component,
      _Ember$computed = Ember.computed,
      alias = _Ember$computed.alias,
      or = _Ember$computed.or,
      readOnly = _Ember$computed.readOnly,
      service = Ember.inject.service,
      set = Ember.set;


  function isSlugRequired(requiredSlugs, availableSlugs, slug) {
    if (availableSlugs.includes(slug)) {
      return false;
    }

    return requiredSlugs.includes(slug);
  }

  exports.default = Component.extend((_dec = (0, _object.computed)('identitySlugs.[]', 'availableSlugs.[]'), _dec2 = (0, _object.computed)('identitySlugs.[]', 'availableSlugs.[]'), _dec3 = (0, _object.computed)('identitySlugs.[]', 'availableSlugs.[]'), _dec4 = (0, _object.computed)('identitySlugs.[]', 'availableSlugs.[]'), (_obj = {
    classNames: ['identity-document-upload'],

    accountOpening: service(),

    citizenshipCountry: readOnly('accountOpening.customer.citizenshipCountry'),

    hasCompletedIdVerification: false,

    hasCompletedAddressVerification: false,

    addressUploaded: false,

    idUploaded: false,

    ssnUploaded: false,

    visaUploaded: false,

    identitySlugs: alias('accountOpening.identitySlugs'),

    availableSlugs: alias('accountOpening.availableSlugs'),

    maxFileSize: _constant.MAX_FILE_SIZE,

    isIdSectionComplete: or('idUploaded', 'hasCompletedIdVerification', 'skippedDocumentUpload'),

    isAddressSectionComplete: or('addressUploaded', 'hasCompletedAddressVerification', 'skippedDocumentUpload'),

    isAddressRequired: function isAddressRequired(requiredSlugs, availableSlugs) {
      return isSlugRequired(requiredSlugs, availableSlugs, _slug.SLUGS.ADDRESS_DOCUMENT);
    },
    isIdRequired: function isIdRequired(requiredSlugs, availableSlugs) {
      return isSlugRequired(requiredSlugs, availableSlugs, _slug.SLUGS.ID_DOCUMENT);
    },
    isSsnRequired: function isSsnRequired(requiredSlugs, availableSlugs) {
      return isSlugRequired(requiredSlugs, availableSlugs, _slug.SLUGS.SSN_DOCUMENT);
    },
    isVisaRequired: function isVisaRequired(requiredSlugs, availableSlugs) {
      return isSlugRequired(requiredSlugs, availableSlugs, _slug.SLUGS.VISA_DOCUMENT);
    },


    actions: {
      addressUploading: function addressUploading() {
        set(this, 'addressUploaded', false);
      },
      idUploading: function idUploading() {
        set(this, 'idUploaded', false);
      },
      ssnUploading: function ssnUploading() {
        set(this, 'ssnUploaded', false);
      },
      visaUploading: function visaUploading() {
        set(this, 'visaUploaded', false);
      },
      addressUploadSuccess: function addressUploadSuccess() {
        set(this, 'addressUploaded', true);
      },
      idUploadSuccess: function idUploadSuccess() {
        set(this, 'idUploaded', true);
      },
      ssnUploadSuccess: function ssnUploadSuccess() {
        set(this, 'ssnUploaded', true);
      },
      visaUploadSuccess: function visaUploadSuccess() {
        set(this, 'visaUploaded', true);
      }
    }
  }, (_applyDecoratedDescriptor(_obj, 'isAddressRequired', [_dec], Object.getOwnPropertyDescriptor(_obj, 'isAddressRequired'), _obj), _applyDecoratedDescriptor(_obj, 'isIdRequired', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'isIdRequired'), _obj), _applyDecoratedDescriptor(_obj, 'isSsnRequired', [_dec3], Object.getOwnPropertyDescriptor(_obj, 'isSsnRequired'), _obj), _applyDecoratedDescriptor(_obj, 'isVisaRequired', [_dec4], Object.getOwnPropertyDescriptor(_obj, 'isVisaRequired'), _obj)), _obj))).reopen(_validation.build({
    isIdSectionComplete: {
      inclusion: {
        in: [true]
      }
    },
    isAddressSectionComplete: {
      inclusion: {
        in: [true]
      }
    },
    ssnUploaded: {
      inclusion: {
        in: [true]
      }
    },
    visaUploaded: {
      inclusion: {
        in: [true]
      }
    }
  }));
});