define('account-opening/models/advanced-trading-request', ['exports', 'twapi/models/advanced-trading-request'], function (exports, _advancedTradingRequest) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _advancedTradingRequest.default;
    }
  });
});