define('account-opening/components/account/margin-restricted-message', ['exports', 'ember-decorators/object', 'twapi/utils/permitted-account-type', 'twapi/models/account-type'], function (exports, _object, _permittedAccountType, _accountType) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _desc, _value, _obj;

  var Component = Ember.Component,
      _Ember$computed = Ember.computed,
      and = _Ember$computed.and,
      notEmpty = _Ember$computed.notEmpty,
      readOnly = _Ember$computed.readOnly,
      service = Ember.inject.service;
  exports.default = Component.extend((_dec = (0, _object.computed)('twapiEndpoints'), _dec2 = (0, _object.computed)('customerPermittedAccountTypes.[]', 'accountTypeName', 'isBusinessAccount', 'entityType'), (_obj = {
    twapiEndpoints: service(),

    accountOpening: service(),

    isBusinessAccount: readOnly('accountOpening.isBusinessAccount'),

    accountTypeName: readOnly('accountOpening.application.accountTypeName'),

    entityType: null,

    hasMarginRestrictedReason: notEmpty('marginRestrictedReason'),

    showMarginRestrictedReason: and('accountOpening.application.isMargin', 'hasMarginRestrictedReason'),

    customerPermittedAccountTypes: function customerPermittedAccountTypes(twapiEndpoints) {
      return twapiEndpoints.getCustomerPermittedAccountTypes();
    },
    marginRestrictedReason: function marginRestrictedReason(customerPermittedAccountTypes, accountTypeName, isBusinessAccount, entityType) {
      if (isBusinessAccount) {
        return _permittedAccountType.default.getReason(customerPermittedAccountTypes, accountTypeName, entityType, _accountType.MARGIN_TYPES.MARGIN);
      }
      return _permittedAccountType.default.getReason(customerPermittedAccountTypes, accountTypeName, _accountType.MARGIN_TYPES.MARGIN);
    }
  }, (_applyDecoratedDescriptor(_obj, 'customerPermittedAccountTypes', [_dec], Object.getOwnPropertyDescriptor(_obj, 'customerPermittedAccountTypes'), _obj), _applyDecoratedDescriptor(_obj, 'marginRestrictedReason', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'marginRestrictedReason'), _obj)), _obj)));
});