define('account-opening/components/account/entity-type-section', ['exports', 'ember-decorators/object', 'twapi/utils/permitted-account-type', 'twapi/models/account-type', 'twapi/models/entity'], function (exports, _object, _permittedAccountType, _accountType, _entity) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _desc, _value, _obj;

  var Component = Ember.Component;
  exports.default = Component.extend((_dec = (0, _object.computed)('residencyPermittedAccountTypes.[]'), _dec2 = (0, _object.computed)('residencyPermittedAccountTypes.[]'), _dec3 = (0, _object.computed)('residencyPermittedAccountTypes.[]'), _dec4 = (0, _object.computed)('residencyPermittedAccountTypes.[]'), _dec5 = (0, _object.computed)('residencyPermittedAccountTypes.[]'), _dec6 = (0, _object.computed)('isSCorpPermitted', 'isCCorpPermitted', 'isLlcPermitted', 'isPartnershipPermitted', 'isTrustPermitted'), (_obj = {
    residencyPermittedAccountTypes: [],

    isSCorpPermitted: function isSCorpPermitted(permittedAccountTypes) {
      return _permittedAccountType.default.isPermitted(permittedAccountTypes, _accountType.ACCOUNT_TYPES.BUSINESS, _entity.ENTITY_TYPES.S_CORP);
    },
    isCCorpPermitted: function isCCorpPermitted(permittedAccountTypes) {
      return _permittedAccountType.default.isPermitted(permittedAccountTypes, _accountType.ACCOUNT_TYPES.BUSINESS, _entity.ENTITY_TYPES.C_CORP);
    },
    isLlcPermitted: function isLlcPermitted(permittedAccountTypes) {
      return _permittedAccountType.default.isPermitted(permittedAccountTypes, _accountType.ACCOUNT_TYPES.BUSINESS, _entity.ENTITY_TYPES.LLC);
    },
    isPartnershipPermitted: function isPartnershipPermitted(permittedAccountTypes) {
      return _permittedAccountType.default.isPermitted(permittedAccountTypes, _accountType.ACCOUNT_TYPES.BUSINESS, _entity.ENTITY_TYPES.PARTNERSHIP);
    },
    isTrustPermitted: function isTrustPermitted(permittedAccountTypes) {
      return _permittedAccountType.default.isPermitted(permittedAccountTypes, _accountType.ACCOUNT_TYPES.TRUST);
    },
    optionValues: function optionValues(isSCorpPermitted, isCCorpPermitted, isLlcPermitted, isPartnershipPermitted, isTrustPermitted) {
      var optionValues = [];

      if (isCCorpPermitted) {
        optionValues.push(_entity.ENTITY_TYPES.C_CORP);
      }
      if (isCCorpPermitted) {
        optionValues.push(_entity.ENTITY_TYPES.S_CORP);
      }
      if (isLlcPermitted) {
        optionValues.push(_entity.ENTITY_TYPES.LLC);
      }
      if (isPartnershipPermitted) {
        optionValues.push(_entity.ENTITY_TYPES.PARTNERSHIP);
      }
      if (isTrustPermitted) {
        optionValues.push(_entity.ENTITY_TYPES.TRUST);
      }

      return optionValues;
    }
  }, (_applyDecoratedDescriptor(_obj, 'isSCorpPermitted', [_dec], Object.getOwnPropertyDescriptor(_obj, 'isSCorpPermitted'), _obj), _applyDecoratedDescriptor(_obj, 'isCCorpPermitted', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'isCCorpPermitted'), _obj), _applyDecoratedDescriptor(_obj, 'isLlcPermitted', [_dec3], Object.getOwnPropertyDescriptor(_obj, 'isLlcPermitted'), _obj), _applyDecoratedDescriptor(_obj, 'isPartnershipPermitted', [_dec4], Object.getOwnPropertyDescriptor(_obj, 'isPartnershipPermitted'), _obj), _applyDecoratedDescriptor(_obj, 'isTrustPermitted', [_dec5], Object.getOwnPropertyDescriptor(_obj, 'isTrustPermitted'), _obj), _applyDecoratedDescriptor(_obj, 'optionValues', [_dec6], Object.getOwnPropertyDescriptor(_obj, 'optionValues'), _obj)), _obj)));
});