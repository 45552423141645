define('account-opening/components/step-nav-bar', ['exports', 'ember-decorators/object', 'account-opening/services/step'], function (exports, _object, _step) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _desc, _value, _obj;

  var get = Ember.get;


  var HIDDEN_STEPS = [_step.REVIEW_STEP];

  exports.default = Ember.Component.extend((_dec = (0, _object.computed)('service.all.@each.isComplete', 'service.nextIncompleteStep'), (_obj = {
    service: Ember.inject.service('step'),
    classNames: ['step-nav-bar', 'nav-bar'],

    steps: function steps(allSteps) {
      return allSteps.reject(function (step) {
        return HIDDEN_STEPS.includes(get(step, 'id'));
      });
    }
  }, (_applyDecoratedDescriptor(_obj, 'steps', [_dec], Object.getOwnPropertyDescriptor(_obj, 'steps'), _obj)), _obj)));
});