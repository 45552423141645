define('account-opening/components/trader/trusted-contact-section', ['exports', 'twapi/utils/validation'], function (exports, _validation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      alias = Ember.computed.alias,
      service = Ember.inject.service;
  exports.default = Component.extend({
    accountOpening: service(),

    hasTrustedContact: alias('accountOpening.hasTrustedContact'),

    trustedContact: alias('accountOpening.trustedContact')
  }).reopen(_validation.default.build({
    hasTrustedContact: {
      presence: true,
      inclusion: { in: [false, true] }
    }
  }));
});