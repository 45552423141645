define('account-opening/components/trader/citizenship-country-body', ['exports', 'ember-decorators/object', 'lodash', 'twapi/utils/country'], function (exports, _object, _lodash, _country) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _dec3, _desc, _value, _obj;

  var Component = Ember.Component,
      readOnly = Ember.computed.readOnly,
      service = Ember.inject.service;
  exports.default = Component.extend((_dec = (0, _object.computed)('isUsCitizen', 'accountOpening.isForeignAccount'), _dec2 = (0, _object.computed)(), _dec3 = (0, _object.computed)(), (_obj = {
    accountOpening: service(),

    hasPendingOrApprovedApplication: readOnly('customer.hasPendingOrApprovedApplication'),

    citizenshipCountryOptions: function citizenshipCountryOptions(isUsCitizen, isForeignAccount) {
      var options = _country.SELECTABLE_COUNTRIES_OPTIONS;

      if (!isUsCitizen) {
        options = _lodash.default.without.apply(_lodash.default, [options].concat(_toConsumableArray(_country.BROKER_HEIGHTENED_RESTRICTION_COUNTRIES_OPTIONS), [_country.USA_COUNTRY_CODE]));
      }
      if (isForeignAccount) {
        options = _lodash.default.without.apply(_lodash.default, [options].concat(_toConsumableArray(_country.RESTRICTED_COUNTRIES_OPTIONS)));
      }

      return options;
    },
    visaExpirationEarliestYear: function visaExpirationEarliestYear() {
      return new Date().getFullYear();
    },
    visaExpirationLatestYear: function visaExpirationLatestYear() {
      return new Date().getFullYear() + 20;
    }
  }, (_applyDecoratedDescriptor(_obj, 'citizenshipCountryOptions', [_dec], Object.getOwnPropertyDescriptor(_obj, 'citizenshipCountryOptions'), _obj), _applyDecoratedDescriptor(_obj, 'visaExpirationEarliestYear', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'visaExpirationEarliestYear'), _obj), _applyDecoratedDescriptor(_obj, 'visaExpirationLatestYear', [_dec3], Object.getOwnPropertyDescriptor(_obj, 'visaExpirationLatestYear'), _obj)), _obj)));
});