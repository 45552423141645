define('account-opening/components/amb-form-international-phone-number', ['exports', 'twapi/components/amb-form-international-phone-number'], function (exports, _ambFormInternationalPhoneNumber) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _ambFormInternationalPhoneNumber.default;
    }
  });
});