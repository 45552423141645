define('account-opening/components/step/security-two-factor', ['exports', 'twapi/models/user-two-factor-method', 'twapi/utils/validation'], function (exports, _userTwoFactorMethod, _validation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var _Ember$computed = Ember.computed,
      alias = _Ember$computed.alias,
      equal = _Ember$computed.equal,
      Component = Ember.Component,
      service = Ember.inject.service,
      set = Ember.set;
  exports.default = Component.extend({
    twapiTwoFactor: service(),

    classNames: 'step-security-two-factor',

    attributeBindings: ['dataTestId:data-test-id'],

    dataTestId: 'two-factor-modal',

    skippedTwoFactor: false,

    showSkipTwoFactor: false,

    twoFactorType: null,

    isValidated: alias('twapiTwoFactor.isEnabled'),

    hasAuthenticator: equal('twoFactorType', _userTwoFactorMethod.TWO_FACTOR_TYPES.AUTHENTICATOR),

    hasSms: equal('twoFactorType', _userTwoFactorMethod.TWO_FACTOR_TYPES.SMS),

    actions: {
      promptSkipTwoFactor: function promptSkipTwoFactor() {
        set(this, 'showSkipTwoFactor', true);
      },
      skipTwoFactor: function skipTwoFactor() {
        set(this, 'skippedTwoFactor', true);
      }
    }
  }).reopen(_validation.build({
    isValidated: { inclusion: { in: [true] } }
  }));
});