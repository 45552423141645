define('account-opening/components/amb-form-segmented-control', ['exports', 'ember-decorators/object', 'ember-ambitious-forms/mixins/converted-options', 'lodash'], function (exports, _object, _convertedOptions, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _desc, _value, _obj;

  exports.default = Ember.Component.extend(_convertedOptions.default, (_dec = (0, _object.computed)('convertedOptions.[]', 'wrapLength'), (_obj = {
    tagName: 'span',
    classNames: ['amb-form-segmented-control'],
    wrapLength: 4,
    small: false,

    optionRows: function optionRows(convertedOptions, wrapLength) {
      return Ember.A(_lodash.default.chunk(convertedOptions, wrapLength));
    }
  }, (_applyDecoratedDescriptor(_obj, 'optionRows', [_dec], Object.getOwnPropertyDescriptor(_obj, 'optionRows'), _obj)), _obj)));
});