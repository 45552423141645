define('account-opening/components/trader/tax-number-field', ['exports', 'ember-decorators/object'], function (exports, _object) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _desc, _value, _obj;

  var Component = Ember.Component,
      dasherize = Ember.String.dasherize,
      get = Ember.get,
      service = Ember.inject.service,
      isPresent = Ember.isPresent,
      set = Ember.set;
  exports.default = Component.extend((_dec = (0, _object.computed)('accountOpening.customer.address.country'), (_obj = {
    classNames: ['columns', 'medium-6', 'small-12'],

    accountOpening: service(),

    i18n: service(),

    foreignTaxNumberLabel: function foreignTaxNumberLabel(country) {
      var lookupKey = 'amb-form.customer.foreign-tax-number';

      return get(this, 'i18n').t('residency-country.' + dasherize(country) + '.' + lookupKey, { default: lookupKey });
    },


    actions: {
      upperCase: function upperCase(input) {
        if (isPresent(input)) {
          set(input, 'value', get(input, 'value').toUpperCase());
        }
      }
    }
  }, (_applyDecoratedDescriptor(_obj, 'foreignTaxNumberLabel', [_dec], Object.getOwnPropertyDescriptor(_obj, 'foreignTaxNumberLabel'), _obj)), _obj)));
});