define('account-opening/helpers/account-type', ['exports', 'ember-i18n/helper'], function (exports, _helper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _helper.default.extend({
    compute: function compute(params, hash) {
      var accountTypeName = hash.accountTypeName || params[0];
      var marginOrCash = hash.marginOrCash || params[1];
      if (!accountTypeName || !marginOrCash) {
        return;
      }

      var key = 'account-type-display.' + accountTypeName.dasherize();

      return this._super([key], { type: marginOrCash });
    }
  });
});