define('account-opening/models/trusted-contact', ['exports', 'twapi/models/trusted-contact'], function (exports, _trustedContact) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _trustedContact.default;
    }
  });
});