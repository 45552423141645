define('account-opening/mixins/reset-scroll-mixin', ['exports', 'twapi/mixins/reset-scroll-mixin'], function (exports, _resetScrollMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _resetScrollMixin.default;
    }
  });
});