define('account-opening/components/beneficiary/twapi-beneficiary-row', ['exports', 'twapi/components/beneficiary/twapi-beneficiary-row'], function (exports, _twapiBeneficiaryRow) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _twapiBeneficiaryRow.default;
    }
  });
});