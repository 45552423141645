define('account-opening/models/equifax-quiz', ['exports', 'twapi/models/equifax-quiz'], function (exports, _equifaxQuiz) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _equifaxQuiz.default;
    }
  });
});