define('account-opening/models/ira-constraints', ['exports', 'twapi/models/ira-constraints'], function (exports, _iraConstraints) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _iraConstraints.default;
    }
  });
});