define('account-opening/models/session-response', ['exports', 'twapi/models/session-response'], function (exports, _sessionResponse) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _sessionResponse.default;
    }
  });
});